<template>
  <div>
    <el-dialog
        title="Chi tiết mô tả"
        :visible.sync="dialogVisible"
        width="450px"
        :before-close="closeDialog"
        :close-on-click-modal="false"
    >
      <div class="description">{{description}}</div>
    </el-dialog>
  </div>
</template>

<script>


export default {
  name: "detailInformationDialogForGroup",
  props: {
    dialogVisible: {
      Type: Boolean,
      default: ()=> false,
    },
    description:{
      Type: String
    }
  },
  data(){
    return{

    }
  },
  methods:{
    closeDialog() {
      this.$emit("dialog-close", false);
    },
  }
}
</script>

<style lang="scss" scoped>
.description {
  width: 400px;
  display: block;
  word-break: break-word;
}
</style>