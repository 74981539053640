<template>

  <div>
    <el-dialog
        :title="'Lịch sử hóa đơn: ' + titleInfoAdd"
        :visible.sync="isOpenHistoryDialogGroup"
        width="1500px"
        :before-close="handleClose"
        :close-on-click-modal="false"
        top="5vh"
    >
      <el-table
          ref="multipleTable"
          :empty-text="textTable"
          highlight-current-row
          :data="historyList"
          :cell-style="tableRowStyle"
          :header-cell-style="tableHeaderColor"
          :max-height="$tableMaxHeight"
          border
      >
        <el-table-column
            type="index"
            fixed
            label="STT"
            width="50"
            align="center"
        ></el-table-column>
        <el-table-column
            width="150"
            fixed
            align="center"
            label="Ngày tạo"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.createdDate | formatDateTime }}</span>

          </template>
        </el-table-column>
        <el-table-column
            width="130"
            fixed
            align="center"
            label="Ngày thanh toán"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.datePayment | formatDate }}</span>

          </template>
        </el-table-column>
        <el-table-column
            label="Người thao tác"
            width="170"
            align="center"
            prop="name"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.nameUserName }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Người thanh toán"
            width="180"
            prop="name"
            align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.parentPay }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Tiền mặt"
                         align="right"
                         width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.cashMoney | formatCurrency }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Chuyển khoản" align="right" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.transferMoney | formatCurrency }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Đã thu" align="right" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.collectedMoney | formatCurrency }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Chuyển thanh toán"
                         min-width="110"
                         align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.transactionEntry==='MONEY_WALLET'">Không chuyển</span>
            <span v-if="scope.row.transactionEntry==='MONEY_MONTH'">Sang tháng sau</span>
          </template>
        </el-table-column>
        <el-table-column label="Mô tả"
                         min-width="180"
                         align="center">
          <template slot-scope="scope">
            <span class="description" @click="detailInformation(scope.row.noteHistory)">{{
                scope.row.noteHistory
              }}</span>
          </template>
        </el-table-column>

      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" size="medium" @click="handleClose()">
          <i class="el-icon-circle-close"/>
          <span>{{ $t("button.close") }}</span>
        </el-button>
      </span>
    </el-dialog>
    <div>
      <DetailInformationDialogForGroup
          :description="description"
          :dialogVisible="showDetailInformation"
          @dialog-close="closeDetailInformation"
          ref="detailInformationDialog"
      />
    </div>
  </div>


</template>

<script>
import GroupExcelService from "@/services/Finance_Kids_V2/GroupExcelService";
import DetailInformationDialogForGroup
  from "@/components/FinanceVer2/ByGroup/TableSearchGroupDialog/DetailInformationDialogForGroup.vue";

export default {
  name: "HistoryOrderKidsDialogGroup",
  components: {DetailInformationDialogForGroup},
  emits: {},
  props: {
    isOpenHistoryDialogGroup: {
      type: Boolean,
      default: () => false,
    },

  },
  data() {
    return {
      textTable: this.$tableEmpty,
      fullName: "",
      orderKidCode: "",
      historyList: [],
      idKid: 0,
      month: 0,
      year: 0,
      collectionTurn: 0,
      loadingButton: false,
      titleInfoAdd: "",
      idGroup: Number,
      description: "",
      showDetailInformation: false,
    }
  },
  computed: {},
  created() {
  },
  methods: {
    detailInformation(description) {
      this.showDetailInformation = true
      this.description = description
    },
    closeDetailInformation() {
      this.showDetailInformation = false
    },
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },
    tableRowStyle({row}) {
      if (row.category === "out") {
        return "color: #409EFF";
      }
    },
    handleClose() {
      this.$emit("closeHistoryOrderKids", false);
    },

    getOrderHistoryInitial(idKid, month, collectionTurn, year, idGroup) {
      this.idKid = idKid;
      this.month = month;
      this.collectionTurn = collectionTurn;
      this.year = year
      this.idGroup = idGroup
      this.searchByProperties();
    },

    searchByProperties() {
      GroupExcelService.getHistoryOrderKidsCodeForGroup(this.idKid, this.month, this.collectionTurn, this.year, this.idGroup)
          .then((resp) => {
            this.historyList = resp.data;
            this.fullName = this.historyList[0].fullName
            this.orderKidCode = this.historyList[0].orderKidCode
            this.titleInfoAdd = this.fullName + " - " + this.orderKidCode + " - D" + this.collectionTurn
          })
          .catch((err) => {
            this.responseList = [];
            this.$message({
              message: err.response.data.message,
              type: "error",
            });
          })

    },

  },
}
</script>

<style lang="scss" scoped>

.el-h1-text {
  font-size: 30px;
  font-weight: inherit;
  color: #eb2020;
}

.div-history {
  min-width: 500px !important;
}

.description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  cursor: pointer
}
</style>
