<template>
  <div class="dialog-container">
    <el-dialog
        width="700px"
        title="Upload file mẫu Excel học phí"
        :visible.sync="isOpenUploadTemplateSchool"
        :before-close="handleCloseTemplateSchool"
        :close-on-click-modal="false"
        @open="handleOpen"
    >
      <div class="upload-section">
        <el-form
            :model="formData"
            label-position="left"
            :rules="rules"
            ref="formData"
        >
          <div class="file-description">
            <el-form-item style="margin-top: 20px">
              <el-radio-group v-model="selectGroup" @change="selectGroupChange">
                <el-radio label="one group">Nhóm hiện tại</el-radio>
                <el-radio label="many group">Chọn nhiều nhóm</el-radio>
                <el-radio label="all group">Chọn tất cả nhóm trong trường</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="this.showSelectGroup === true">
              <!-- chọn nhóm -->
              <el-select
                  @visible-change="handleChangeKidGroup"
                  @remove-tag="handleChangeKidGroup"
                  @clear="handleChangeKidGroup"
                  multiple
                  collapse-tags
                  clearable
                  class="input-common input-grade"
                  style="width: 200px"
                  v-model="idGroupList"
                  placeholder="Chọn nhóm"
              >
                <el-option
                    v-for="(item,index) in kidGroupList"
                    :key="index"
                    :value="item.id"
                    :label="item.nameGroup"
                ></el-option>
              </el-select>
              <span v-if="this.idGroupList.length === 0"
                    style="margin-left: 10px; color: red">* Vui lòng chọn nhóm</span>
            </el-form-item>
            <el-form-item label="Nhập mô tả" prop="sendDescription">
              <el-input
                  type="textarea"
                  :rows="2"
                  v-model="formData.sendDescription"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item>
            <div class="file-upload-field">
              <el-upload
                  action="#"
                  :auto-upload="false"
                  :on-change="toggleUpload"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  ref="upload"
                  accept=".xls,.xlsx"
                  :file-list="formData.fileList"
              >
                <el-button size="small" type="primary" style="font-size: 15px">Upload file <i
                    class="el-icon-upload el-icon-right"></i></el-button>
              </el-upload>
            </div>
          </el-form-item>
        </el-form>
        <div class="buttons">
          <el-button
              :loading="loadingUploadTemplate"
              size="small"
              type="success"
              style="margin-right: 10px"
              @click="handleUpload()"
              v-if="this.checkButton === true"
          >
            Tải lên
          </el-button>
          <el-button size="small" type="danger" @click="handleCloseTemplateSchool">
            <i class="el-icon-circle-close"/> Đóng
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment/moment";
import {mapState} from "vuex";
import groupExcelService from "@/services/Finance_Kids_V2/GroupExcelService";

export default {
  name: "UploadTemplateSchoolForGroup",
  data() {
    return {
      selectGroup: "one group",
      idGroupList: [],
      showSelectGroup: false,
      loadingUploadTemplate: false,
      checkButton: false,
      selectedFile: null,
      showUploadButton: false,
      year: moment().format("YYYY"),
      formData: {
        sendDescription: "",
        fileList: [],
      },
      rules: {
        sendDescription: [
          {
            required: true,
            message: "Mô tả không được để trống!",
            trigger: "change",
          },
        ],
      },
    };
  },
  props: {
    idGroup: {
      type: String,
    },
    kidGroupList: {
      type: Array,
      default: () => []
    },
    isOpenUploadTemplateSchool: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapState('groupExcelModule', ['dataSearch'])
  },
  watch: {
    idGroupList() {
      if (this.idGroupList.length === 0) {
        this.checkButton = false;
      }
      this.checkShowButton()
    },
    'formData.sendDescription': function () {
      if (this.formData.sendDescription === '') {
        this.checkButton = false;
      }
      this.checkShowButton()
    },
    'formData.fileList': function () {
      if (this.formData.fileList.length === 0) {
        this.checkButton = false;
      }
      this.checkShowButton()
    }
  },
  methods: {
    handleOpen(){
      this.selectGroupChange()
    },
    handleCloseTemplateSchool() {
      this.$store.dispatch("groupExcelModule/init_kids_data", this.dataSearch);
      this.$emit("close", false);
      this.clearForm();
    },

    handleRemove(file, fileList) {
      this.showUploadButton = fileList.length === 0;
      this.checkShowButton()
    },
    handlePreview() {
      this.checkShowButton()
    },
    beforeRemove(file) {
      return new Promise((resolve, reject) => {
        this.$confirm(`Bạn chắc chắn muốn xóa ${file.name} ?`)
            .then(() => {
              this.showUploadButton = false;
              this.$refs.upload.clearFiles();
              this.checkShowButton()
            })
            .catch(() => {
              reject();
            });
      });
    },
    async handleUpload() {
      this.loadingUploadTemplate = true;
      try {
        if (!this.formData.fileList || this.formData.fileList.length === 0) {
          console.error("Không có file được chọn");
          this.loadingUploadTemplate = false;
          return;
        }
        const formData = new FormData();
        formData.append("idGroupList", this.idGroupList);
        formData.append("description", this.formData.sendDescription);
        formData.append("year", this.dataSearch.createdYear);
        for (const file of this.formData.fileList) {
          formData.append("nameFile", file.raw);
        }
        await groupExcelService.uploadFileTemplateForGroup(formData).then((res) => {
          if (res.data.message !== "Tải file thất bại") {
            this.$notify.success({
              title: "Thông báo",
              message: "Upload dữ liệu thành công",
            });
            this.loadingUploadTemplate = false;
          } else {
            this.$message({
              message: "Upload dữ liệu thất bại. Kiểm tra lại cấu trúc trong File!",
              type: "error",
            });
            this.loadingUploadTemplate = false;
          }
        })
        this.loadingUploadTemplate = false;
        this.showUploadButton = false;
        this.$refs.upload.clearFiles();
        this.handleCloseTemplateSchool();
      } catch (error) {
        this.$message({
          message: "Upload dữ liệu thất bại. Kiểm tra lại cấu trúc trong File!",
          type: "error",
        });
        this.loadingUploadTemplate = false;
      }
    },

    toggleUpload(file, fileList) {
      this.formData.fileList = fileList.slice(-1);
      this.showUploadButton = fileList.length > 0;
      this.checkShowButton()
    },

    //   Xóa form
    clearForm() {
      this.selectGroup = "one group"
      this.checkButton = false
      this.idGroupList = []
      this.showUploadButton = false;
      this.loadingUploadTemplate = false;
      this.$refs.formData.resetFields()
      this.$refs.upload.clearFiles();
    },

    //   Chọn radio
    selectGroupChange() {
      this.idGroupList = [];
      if (this.selectGroup === "one group") {
        this.showSelectGroup = false;
        this.idGroupList.push(parseInt(this.dataSearch.idGroup))
      } else if (this.selectGroup === "many group") {
        this.showSelectGroup = true;
      } else if (this.selectGroup === "all group") {
        this.showSelectGroup = false;
      }
      this.checkShowButton()
    },

    //   Chọn nhóm
    handleChangeKidGroup() {
      this.checkShowButton()
    },

    //   Check show button tải lên
    checkShowButton() {
      this.checkButton = false;
      if (this.selectGroup === "many group") {
        if (this.idGroupList.length !== 0 && this.showUploadButton === true && this.formData.sendDescription !== '') {
          return this.checkButton = true;
        } else return this.checkButton = false;
      } else if (this.showUploadButton === true && this.formData.sendDescription !== '') {
        return this.checkButton = true;
      } else this.checkButton = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.file-upload-label {
  margin-top: 10px;
  text-align: left;
  margin-bottom: 5px;
}

.file-upload-field {
  display: flex;
  justify-content: left;
}

.buttons {
  display: flex;
  justify-content: right;
  text-align: right;
  margin-top: 10px;
}

.buttons button {
  font-size: 15px;
}

</style>
