var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Lịch sử hóa đơn: " + _vm.titleInfoAdd,
            visible: _vm.isOpenHistoryDialogGroup,
            width: "1500px",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isOpenHistoryDialogGroup = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: {
                "empty-text": _vm.textTable,
                "highlight-current-row": "",
                data: _vm.historyList,
                "cell-style": _vm.tableRowStyle,
                "header-cell-style": _vm.tableHeaderColor,
                "max-height": _vm.$tableMaxHeight,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  fixed: "",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "150",
                  fixed: "",
                  align: "center",
                  label: "Ngày tạo",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDateTime")(scope.row.createdDate)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  width: "130",
                  fixed: "",
                  align: "center",
                  label: "Ngày thanh toán",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatDate")(scope.row.datePayment))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Người thao tác",
                  width: "170",
                  align: "center",
                  prop: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.nameUserName))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Người thanh toán",
                  width: "180",
                  prop: "name",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.parentPay))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Tiền mặt", align: "right", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatCurrency")(scope.row.cashMoney)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Chuyển khoản", align: "right", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatCurrency")(scope.row.transferMoney)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Đã thu", align: "right", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatCurrency")(scope.row.collectedMoney)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Chuyển thanh toán",
                  "min-width": "110",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.transactionEntry === "MONEY_WALLET"
                          ? _c("span", [_vm._v("Không chuyển")])
                          : _vm._e(),
                        scope.row.transactionEntry === "MONEY_MONTH"
                          ? _c("span", [_vm._v("Sang tháng sau")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Mô tả", "min-width": "180", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "description",
                            on: {
                              click: function ($event) {
                                return _vm.detailInformation(
                                  scope.row.noteHistory
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.noteHistory))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.handleClose()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("DetailInformationDialogForGroup", {
            ref: "detailInformationDialog",
            attrs: {
              description: _vm.description,
              dialogVisible: _vm.showDetailInformation,
            },
            on: { "dialog-close": _vm.closeDetailInformation },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }