import groupExcelService from "@/services/Finance_Kids_V2/GroupExcelService";

export default {

    methods: {
        // Gửi SMS
        sendSMS(idKidList, date, collectionTurn,idGroup) {
            const idKidListArray = idKidList.split(',').map(Number);
            if (idKidList === "") {
                this.$message({
                    type: "error",
                    message: "Chưa có học sinh nào được chọn.",
                });
            } else {
                this.$confirm(
                    "Số lượng tin nhắn sẽ bị trừ vào quỹ tin của NHÀ TRƯỜNG! <br> Bạn có chắc chắn muốn gửi không?",
                    {
                        distinguishCancelAndClose: true,
                        confirmButtonText: "Có",
                        closeOnClickModal: false,
                        cancelButtonText: "Không",
                        dangerouslyUseHTMLString: true
                    }).then(() =>{
                    const request = {
                        date: date,
                        idKidList: idKidListArray,
                        collectionTurn: collectionTurn,
                        idGroup

                    };
                    groupExcelService.sendSMSForGroup(request).then((res) => {

                        if (res.data.message === "Chưa kích hoạt") {
                            this.$message({
                                message: "PHHS chưa kích hoạt tài khoản",
                                type: "error",
                                duration: 5000
                            });

                        }else {
                            this.$message({
                                message: res.data.message,
                                type: "success",
                            });
                        }
                    });
                })

            }
        },
    }
}