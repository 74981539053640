var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.inventoryList.length !== 0
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "Tồn hóa đơn: " + _vm.fullName + " - " + _vm.kidCode,
                visible: _vm.isOpenInventoryOrder,
                width: "1510px",
                "before-close": _vm.handleClose,
                "close-on-click-modal": false,
                top: "5vh",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.isOpenInventoryOrder = $event
                },
              },
            },
            [
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  attrs: {
                    "highlight-current-row": "",
                    data: _vm.inventoryList,
                    "cell-style": _vm.tableRowStyle,
                    "header-cell-style": _vm.tableHeaderColor,
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      fixed: "",
                      label: "STT",
                      width: "50",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      width: "90",
                      fixed: "",
                      align: "center",
                      label: "Tháng",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.month))]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3617326326
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Mã hóa đơn",
                      width: "180",
                      align: "center",
                      prop: "name",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.orderKidCode)),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      740204035
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Phải thu tháng này",
                      width: "140",
                      prop: "name",
                      align: "right",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrency")(scope.row.sumMoney)
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3358785088
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Đã thu", align: "right", width: "120" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      scope.row.collectedMoney
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3623149396
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Tiền mặt", align: "right", width: "120" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      scope.row.cashMoney
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      850398546
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Chuyển khoản",
                      align: "right",
                      width: "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      scope.row.transferMoney
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1603602976
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Thiếu / Thừa tháng trước",
                      align: "right",
                      width: "130",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      scope.row.beforeMoney
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      803904594
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Thiếu / Thừa tháng này",
                      align: "right",
                      width: "130",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.afterMoney < 0
                                ? _c("span", [
                                    _vm._v(
                                      "(" +
                                        _vm._s(
                                          _vm._f("formatCurrency")(
                                            Math.abs(scope.row.afterMoney)
                                          )
                                        ) +
                                        ")"
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          scope.row.afterMoney
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3424999434
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Chuyển thanh toán",
                      align: "center",
                      width: "150",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.transactionEntry === "MONEY_WALLET"
                                ? _c("span", [_vm._v("Không chuyển")])
                                : _vm._e(),
                              scope.row.transactionEntry === "MONEY_MONTH"
                                ? _c("span", [_vm._v("Sang tháng sau")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4214664991
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Đợt thu", align: "center", width: "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.collectionTurn)),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1035812397
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Tác vụ",
                      align: "center",
                      "min-width": "120",
                      width: "140",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.checkPermission(["fees_2_excel_cash_payment"])
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "success", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.orderKidsCashierMethod(
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Thanh toán\n            ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1148082151
                    ),
                  }),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "medium" },
                      on: {
                        click: function ($event) {
                          return _vm.handleClose()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-close" }),
                      _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("OrderKidExcelCashierGroup", {
        ref: "OrderKidsExcelCashier",
        attrs: { isOpenCashierDialog: _vm.showOrderKidsExcelCashierDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeOrderKidsExcelCashierDialog()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }