<template>
  <el-dialog
      :before-close="handleClose"
      :close-on-click-modal="false"
      :title="'Chi tiết khoản thu : '+ nameKT "
      :visible.sync="isOpenDetailKT"
      top="5vh"
      width="500px"
  >
    <el-form ref="formReq" :label-position="labelPosition" :model="formReq" :rules="rules">
      <el-form-item :label-width="'80px'" label="Số lượng" prop="quantityKT">
        <el-input-number :disabled="isDisabled"  v-model="formReq.quantityKT"  ></el-input-number>
        <span v-if="formReq.quantityKT<0" class="error-message"> &nbsp; &nbsp;* Nhà trường hoàn tiền.</span>
      </el-form-item>
      <el-form-item :label-width="'80px'" label="Đơn giá" prop="priceKT">
        <el-currency-input id="priceKT" v-model="formReq.priceKT" :disabled="isDisabled" autocomplete="off"
                           style="text-align: center"
        ></el-currency-input>
      </el-form-item>
      <el-form-item :label-width="'80px'" label="Giảm giá" prop="discountKT">
        <el-currency-input id="discountKT" v-model="formReq.discountKT" :disabled="isDisabled" autocomplete="off"
                           style="text-align: center" type="number"
        ></el-currency-input>
      </el-form-item>
    </el-form>
    <div style="font-size: 20px; margin-left: 1px;">Thành tiền :
      {{ (Number(formReq.quantityKT) * Number(formReq.priceKT)) - formReq.discountKT | formatCurrency }}
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button v-show="isEditing" type="primary" @click="saveDetailKT('formReq')" :loading = "loadingButton">Cập nhật </el-button>
      <el-button v-if="this.statusOrder !== 'Ẩn hóa đơn'" v-show="!isEditing" type="success"
                 @click="editForm">Sửa</el-button>
    <el-button  type="danger" @click="handleClose"> <i class="el-icon-circle-close"/> Đóng</el-button>
      </span>
  </el-dialog>
</template>

<script>

import {mapState} from "vuex";
import groupExcelService from "@/services/Finance_Kids_V2/GroupExcelService";

export default {
  name: "DetailKTForGroupDialog",

  computed: {
    ...mapState("groupExcelModule", ["dataSearch"]),
  },


  data() {
    const checkPrice = (rule, value, callback) => {
      if(value === '' || value === null){
        callback(new Error('Không được để trống!'));
      }
      if (this.formReq.quantityKT > 0 && Number(value) > Number(this.formReq.priceKT) * Number(this.formReq.quantityKT)&&Number(this.formReq.priceKT) * Number(this.formReq.quantityKT)>0) {
        callback(new Error('Giảm giá không lớn hơn tiền khoản thu'));
      } else {
        callback();
      }
    }
    return {
      loadingButton : false,
      rules: {
        discountKT: [{
          validator: checkPrice,
          trigger: 'change',
          required: true,
        }],
        priceKT: [{
          required: true,
          message: "Không được để trống!",
          trigger: "change",
        }],
        quantityKT: [{
          required: true,
          message: "Không được để trống!",
          trigger: "change",
        }],
      },
      nameKT: "",
      isDisabled: true,
      isEditing: false,
      formReq: {
        priceKT: 0,
        quantityKT: 0,
        discountKT: 0,
      },
      dataResp: {
        month: 0,
        moneyKt: 0,
        priceKt: 0,
        quantityKt: 0,
        discountKt: 0,
        year: 0,
        idGroup:Number
      },
      dataReq: {
        kidCode: "",
        month: "",
        collectionTurn: "",
        nameKt: "",
        idGroup:Number,
      },
      labelPosition: 'left',
      statusOrder: "",
    }
  },

  watch: {
    // formReq.quantityKT
  },

  props: {
    isOpenDetailKT: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    fixedValue(){
      this.formReq.quantityKT = this.formReq.quantityKT.replace(/(\+\+|\-\-)/g, '');

      // Remove "e" character
      this.formReq.quantityKT= this.formReq.quantityKT.replace(/e/g, '');
    },
   async showDetailKT(month, kidCode, collectionTurn, nameKt, statusOrder, year,idGroup) {
      this.statusOrder = statusOrder;
      this.dataReq.month = month;
      this.dataReq.kidCode = kidCode;
      this.dataReq.collectionTurn = collectionTurn;
      this.dataReq.nameKt = nameKt;
      this.dataReq.year = year;
      this.dataReq.idGroup=idGroup
      this.nameKT = nameKt;
      await groupExcelService.showDetailPaymentForGroup(this.dataReq).then((resp) => {
        this.formReq.quantityKT = resp.data.quantityKt;
        this.formReq.priceKT = resp.data.priceKt;
        this.formReq.discountKT = resp.data.discountKt;
      })
    },

    saveDetailKT(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loadingButton = true
          this.dataResp.idGroup=this.dataSearch.idGroup
          this.dataResp.month = this.dataSearch.month;
          this.dataResp.year = this.dataSearch.createdYear;
          this.dataResp.quantityKt = this.formReq.quantityKT;
          this.dataResp.priceKt = this.formReq.priceKT;
          this.dataResp.discountKt = this.formReq.discountKT;
          this.dataResp.moneyKt = (this.formReq.quantityKT * this.formReq.priceKT) - this.formReq.discountKT;
          groupExcelService.showDetailPaymentForGroup(this.dataReq).then((resp) => {
            if (this.dataResp.quantityKt === resp.data.quantityKt && this.dataResp.priceKt === resp.data.priceKt && this.dataResp.discountKt === resp.data.discountKt) {
              this.$message({
                message: "Không có dữ liệu thay đổi",
                type: "error",
              });
              this.loadingButton = false
            }
            else {
              groupExcelService.saveDetailKTForGroup(this.dataResp).then(() => {
                this.$message({
                  message: "Cập nhật thành công",
                  type: "success",
                });
                this.loadingButton = false
              }).then(() => {
                this.$store.commit('groupExcelModule/get_status_excel')
                this.handleClose()
              }).catch((err) => {
                this.$message({
                  message: err.response.data.message,
                  type: "error",
                });
              });
            }
          })
        } else {
          this.loadingButton = false
        }
      });


    },

    handleClose() {
      this.loadingButton = false
      this.isDisabled = true;
      this.isEditing = false
      this.$refs.formReq.resetFields();
      this.$store.dispatch("groupExcelModule/init_kids_data", this.dataSearch);
      this.$emit("closeDetailKT", false);
    },
    editForm() {
      this.isDisabled = false;
      this.isEditing = true;
    }
  },


}
</script>

<style scoped>

.el-form-item .error-message {
  color: red;
}

</style>