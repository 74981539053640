<template>
  <div>

    <!--    Dialog hiển thị danh sách thông báo đúng thông tin-->

    <el-dialog
        title="Danh sách học sinh"
        :visible.sync="dialogFormVisible"
        width="1275px"
        :before-close="handleClose"
        :close-on-click-modal="false">
      <div style="margin-bottom: 1%">
        <span style="color: green">&#10004; : Thông tin không thay đổi</span>
        <span style="color: red; margin-left: 7%">&#10008; : Thông tin đã thay đổi</span>
      </div>
      <el-table
          v-if="listConfirm.length !==0"
          :empty-text="textTable"
          ref="multipleTable"
          :data="listConfirm"
          :header-cell-style="tableHeaderColor"
          :max-height="400"
          @selection-change="handleSelectionChange"
          border
      >
        <el-table-column
            type="selection"
            align="center"
            width="55"
        >
        </el-table-column>
        <el-table-column
            type="index"
            fixed
            label="STT"
            width="50"
            align="center"
        ></el-table-column>
        <el-table-column
            width="150"
            fixed
            align="center"
            label="Họ và tên"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Mã học sinh"
            width="120"
            align="center"
            prop="name"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.kidCode }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Lớp"
            width="120"
            align="center"
            prop="name"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.nameClass }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Thông tin học sinh"
            width="120"
            prop="name"
            align="center"
        >
          <template slot-scope="scope">
            <span style="color: green" v-if="scope.row.notifyInfo === 'true'">&#10004;</span>
            <span style="color: red" v-else>&#10008;</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Thông tin khoản thu"
            align="center"
            width="120">
          <template slot-scope="scope">
            <span style="color: green" v-if="scope.row.notifyPayment === 'true'">&#10004;</span>
            <span style="color: red" v-else>&#10008;</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Thông tin khoản thu âm"
            align="center"
            width="120">
          <template slot-scope="scope">
            <span style="color: green" v-if="scope.row.notifyMinusPayment === 'true'">&#10004;</span>
            <span style="color: red" v-else>&#10008;</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Tiền thanh toán"
            align="center"
            width="120">
          <template slot-scope="scope">
            <span style="color: green" v-if="scope.row.notifyMoney === 'true'">&#10004;</span>
            <span style="color: red" v-else>&#10008;</span>
          </template>
        </el-table-column>

        <el-table-column
            label="Số liệu điểm danh"
            align="center"
            width="129">
          <template slot-scope="scope">
            <span style="color: green" v-if="scope.row.notifyAttendance === 'true'">&#10004;</span>
            <span style="color: red" v-else>&#10008;</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Số liệu điểm danh âm"
            align="center"
            width="129">
          <template slot-scope="scope">
            <span style="color: green" v-if="scope.row.notifyMinusAttendance === 'true'">&#10004;</span>
            <span style="color: red" v-else>&#10008;</span>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-row class="overwrite" v-if="listConfirm.length !==0">
        <el-col :span="8">
          <div>
            <el-checkbox
                v-model="overwriteAttendance"
            >Cho phép ghi đè điểm danh
            </el-checkbox>
          </div>
        </el-col>
        <el-col :span="8">
          <div>
            <el-checkbox
                v-model="overwritePayment"
            >Cho phép ghi đè khoản thu
            </el-checkbox>
          </div>
        </el-col>
        <el-col :span="8">
          <div>
            <el-checkbox v-model="overwriteNote"
            >Cho phép ghi đè ghi chú
            </el-checkbox>
          </div>
        </el-col>
      </el-row>


      <el-row class="overwrite" v-if="listConfirm.length !==0" style="margin-top: 25px">
        <el-col :span="8">
          <div class="checkbox-container">
            <el-checkbox disabled v-model="overwriteMoney">
              <span style="color: red">Ghi đè tiền thanh toán từ File Excel</span>
            </el-checkbox>
          </div>
        </el-col>
        <el-col :span="8">
          <el-checkbox style="color: black" v-model="checkCreateMoney" @change="changeCreateMoney">Thêm mới tiền đã thu
            (Lần đầu)
          </el-checkbox>
        </el-col>
      </el-row>
      <el-row class="overwrite" v-if="listConfirm.length !==0" style="margin-top: 20px">
        <el-col :offset="8">
          <el-checkbox style="color: black" v-model="checkUpdateMoney" @change="changeUpdateMoney">Cập nhật tiền đã
            thu
          </el-checkbox>
        </el-col>
        <el-col :offset="8" v-if="updateMoney==='UPDATE_MONEY'" style="margin-top: 15px">
          <el-radio-group v-model="statusTransactionEntry">
            <el-radio style="color: black" label="MONEY_WALLET">Không chuyển</el-radio>
            <el-radio style="color: black" label="MONEY_MONTH">Sang tháng sau</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>


      <br>
      <br>
      <el-row>
        <el-col :offset="20" :span="8">
          <el-button type="primary" :loading="loadingImport" @click="handleClickImportButton">Lưu</el-button>
          <el-button type="danger" @click="handleClose"
          ><i class="el-icon-circle-close"/> Đóng
          </el-button>
        </el-col>
      </el-row>

    </el-dialog>

    <!--    Dialog hiển thị danh sách thông báo sai thông tin-->

    <el-dialog
        title="Danh sách học sinh sai thông tin"
        :visible.sync="dialogFailInformation"
        width="1153px"
        :before-close="handleClose"
        :close-on-click-modal="false">
      <el-table
          v-if="listError!=0"
          :empty-text="textTable"
          ref="multipleTable"
          :data="listError"
          :header-cell-style="tableHeaderColor"
          @selection-change="handleSelectionChange"
          border
          :max-height="400"
      >
        <el-table-column
            type="index"
            fixed
            label="STT"
            width="50"
            align="center"
        ></el-table-column>
        <el-table-column
            width="160"
            fixed
            align="center"
            label="Họ và tên"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.name==null">
              <span style="color: red">Không tìm thấy học sinh</span>
            </div>
            <div v-else>
              <span style="color: cornflowerblue">{{ scope.row.name }}</span>
              <br>
              <span style="color: red">{{ scope.row.nameFail }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="Mã học sinh"
            width="150"
            align="center"
            prop="name"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.kidCode === null">
              <span style="color: red">{{ scope.row.kidCodeFail }}</span>
            </div>
            <div v-else>
              <span style="color: cornflowerblue">{{ scope.row.kidCode }}</span>
              <br>
              <span style="color: red">{{ scope.row.kidCodeFail }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="Ngày sinh"
            width="150"
            align="center"
            prop="name"
        >
          <template slot-scope="scope">
            <span style="color: cornflowerblue">{{ scope.row.birthDay }}</span>
            <br>
            <span style="color: red">{{ scope.row.birthDayFail }}</span>

          </template>
        </el-table-column>
        <el-table-column
            label="Số điện thoại"
            width="150"
            align="center"
            prop="name"
        >
          <template slot-scope="scope">

            <span style="color: cornflowerblue">{{ scope.row.phoneParent }}</span>
            <br>
            <span style="color: red">{{ scope.row.phoneParentFail }}</span>

          </template>
        </el-table-column>
        <el-table-column
            label="Khối"
            width="150"
            align="center"
            prop="name"
        >
          <template slot-scope="scope">
            <span style="color: cornflowerblue">{{ scope.row.nameGrade }}</span>
            <br>
            <span style="color: red">{{ scope.row.nameGradeFail }}</span>

          </template>
        </el-table-column>
        <el-table-column
            label="Lớp"
            width="150"
            align="center"
            prop="name"
        >
          <template slot-scope="scope">
            <span style="color: cornflowerblue">{{ scope.row.nameClass }}</span>
            <br>
            <span style="color: red">{{ scope.row.nameClassFail }}</span>
          </template>
        </el-table-column>


        <el-table-column
            label="Nhóm"
            width="150"
            align="center"
            prop="name"
        >
          <template slot-scope="scope">
            <span style="color: cornflowerblue">{{ scope.row.nameGroup }}</span>
            <br>
            <span style="color: red">{{ scope.row.nameGroupFail }}</span>
          </template>
        </el-table-column>

      </el-table>
      <div class="custom_button_error">
        <div>
          <span style="color: red; margin-right: 480px">* Mời bạn nhấn "Tiếp tục" để hiển thị danh sách học sinh đúng thông tin</span>
        </div>
        <div>
          <el-button v-if="isContinue" type="primary" @click="handleClickContinue" :loading="loadingImport">Tiếp tục
          </el-button>
          <el-button type="danger" @click="handleClose"
          ><i class="el-icon-circle-close"/> Đóng
          </el-button>
        </div>
      </div>
    </el-dialog>

    <!--    Dialog thêm đợt thu-->

    <el-dialog
        width="580px"
        title="Tạo đợt thu"
        :visible.sync="isOpenImportChecker"
        :before-close="handleClose"
        :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="Chọn tháng/đợt thu" label-width="150px">
          <el-row>
            <el-col :span="21">
              <el-date-picker
                  style="width: 135px"
                  class="custom-date-picker"
                  :clearable="true"
                  v-model="monthAndYearUpload"
                  type="month"
                  @change="handelChangeDate"
                  placeholder="Chọn tháng"
                  value-format="M-yyyy"
                  format="MM-yyyy"
                  :time-arrow-control="false"
              >
              </el-date-picker>
              <el-select
                  style="width: 130px; margin-left: 10px"
                  class="input-common"
                  v-model="turn"
                  placeholder="Đợt thu"
                  clearable
                  @change="showStartAndEndDate"
              >
                <el-option
                    v-for="item in collectionTurn"
                    :key="item"
                    :value="item"
                    :label="`Đợt ${item}`"
                ></el-option>
              </el-select>
              <el-button @click="handleOpenInputDescriptionTurn">
                <i
                    class="el-icon-circle-plus"
                ></i>
              </el-button>
<!--              <span v-if="isInputDescriptionTurn" style="margin-left: 15px">{{ "Đợt " + form.collectionTurn }}</span>-->
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item v-if="isInputDescriptionTurn">
          <el-row>
            <el-col :span="4">
              <span>Mô tả đợt thu</span>
            </el-col>
            <el-col :span="14" style="margin-left: 60px">
              <el-input v-model="form.descriptionTurn" autocomplete="off" placeholder="Mời nhập mô tả đợt thu"
                        type="textarea"
                        style="width: 335px; font-family: Roboto,serif"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="Khoảng thời gian" label-width="140px">
          <el-date-picker
              style="width:335px; margin-left: 10px"
              format="dd-MM-yyyy"
              v-model="listDay"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="-"
              start-placeholder="Ngày bắt đầu"
              end-placeholder="Ngày kết thúc"
              :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
      </el-form>

      <div class="file-upload-field">
        <el-upload
            action="#"
            :auto-upload="false"
            :on-change="handleUpload"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            ref="upload"
            accept=".xls,.xlsx"
            :file-list="fileList"
            :limit="1"
            :close-on-click-modal="false"
        >
          <el-button size="small" type="primary" style="font-size: 15px">Tải file <i
              class="el-icon-upload el-icon-right"></i></el-button>
          <span style="margin-left: 55px">Đề nghị tải file Excel theo đúng định dạng của Onekids</span>
        </el-upload>

      </div>
      <div class="custom_button">
        <el-button type="primary" @click="handleExcelImport" v-if="showUploadButton" :loading="loadingImport">Upload <i
            class="el-icon-upload el-icon-right"></i></el-button>
        <el-button @click="handleClose" type="danger" style="margin-right: 45px"><i class="el-icon-circle-close"/> Đóng</el-button>
      </div>
    </el-dialog>
  </div>


</template>
<script>

import {mapState} from "vuex";
import moment from "moment";
import groupExcelService from "@/services/Finance_Kids_V2/GroupExcelService";

export default {
  name: "KidExcelImportCheckerForGroup",
  emits: {},
  computed: {
    ...mapState("groupExcelModule", ["totalPages", "dataSearch"]),
  },
  props: {
    isOpenImportChecker: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: 'Tuần trước',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Tháng trước',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '3 Tháng trước',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        },
          {
            text: '6 Tháng trước',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit('pick', [start, end]);
            }
          },]
      },
      monthAndYearUpload: "",
      loadingImport: false,
      startDateExcel: null,
      endDateExcel: null,
      value1: '',
      listDay: [],
      checkCreateMoney: true,
      checkUpdateMoney: false,
      textTable: this.$tableEmpty,
      excelFile: null,
      turn: null,
      collectionTurn: null,

      listError: [],
      listConfirm: [],
      multipleSelection: [],

      /**
       * Dữ liêu thông báo thông tin
       */
      checkKidCode: "",
      checkStructureTable: "",
      checkInformation: "",
      checkAttendance: "",
      checkPayment: "",
      checkMoney: "",

      fileList: [],
      showUploadButton: false,
      isContinue: true,
      /**
       * Dữ liêu ghi đè khi lưu vào DB
       */
      overwriteAttendance: true,
      overwritePayment: true,
      overwriteMoney: true,
      overwriteNote: true,
      statusTransactionEntry: "MONEY_WALLET",
      updateMoney: "CREATE_MONEY",

      dataReturn: "",
      dialogFormVisible: false,
      dialogFailInformation: false,
      isInputDescriptionTurn: false,
      month: "",
      year: "",
      groupNameCurrent: "",

      /**
       * Dữ liệu trong Form tạo đợt thu mới
       */
      form: {
        descriptionTurn: "",
        collectionTurn: 1
      },
      paramSaveExcelToDB: [],

    };
  },
  methods: {
   async handelChangeDate(val) {
      this.turn = null
      this.isInputDescriptionTurn = false
      this.startDateExcel = null
      this.endDateExcel = null
      this.listDay = []
      this.form.descriptionTurn = ''
      if (val === null || val === '') {
        this.monthAndYearUpload = ""
      } else {
        this.month = val.split('-')[0]
        this.year = val.split('-')[1]
      await  groupExcelService.getCollectionTurn(
            this.month,
            this.dataSearch.idGroup,
            this.year
        ).then((res) => {
          console.log("data: ",res.data)
          this.collectionTurn = res.data;
          this.form.collectionTurn = this.collectionTurn + 1
        });
      }
    },

    async showStartAndEndDate() {
      this.isInputDescriptionTurn = false
      this.startDateExcel = null
      this.endDateExcel = null
      this.listDay = []
      let param = {
        collectionTurn: this.turn,
        month: this.month,
        year: this.year,
        idSchool: this.dataSearch.idSchool,
        idGroup: this.dataSearch.idGroup,
      }


    await groupExcelService.showStartAndEndDate(param).then((response) => {
        this.startDateExcel = moment(response.data.startDateExcel).format('MM-DD-YYYY')
        this.endDateExcel = moment(response.data.endDateExcel).format('MM-DD-YYYY')
        this.listDay = [this.startDateExcel, this.endDateExcel]
      })
    },

    changeUpdateMoney() {
      this.checkCreateMoney = false
      if (this.checkUpdateMoney === true) {
        this.updateMoney = 'UPDATE_MONEY'
      } else {
        this.checkCreateMoney = true
        this.updateMoney = 'CREATE_MONEY'
      }
      this.statusTransactionEntry = 'MONEY_WALLET'
    },
    changeCreateMoney() {
      this.checkUpdateMoney = false
      if (this.checkCreateMoney === true) {
        this.updateMoney = 'CREATE_MONEY'
      } else {
        this.checkUpdateMoney = true
        this.updateMoney = 'UPDATE_MONEY'
      }
      this.statusTransactionEntry = 'MONEY_WALLET'
    },

    toggleUpload(file, fileList) {
      this.fileList = fileList
      this.showUploadButton = fileList.length > 0;
    },
    handleRemove(file, fileList) {
      this.showUploadButton = fileList.length === 0;
    },
    handlePreview() {
    },
    beforeRemove(file) {
      return new Promise((resolve, reject) => {
        this.$confirm(`Bạn chắc chắn muốn xóa ${file.name} ?`)
            .then(() => {
              this.showUploadButton = false;
              this.$refs.upload.clearFiles();
            })
            .catch(() => {
              reject();
            });
      });
    },

    handleClickContinue() {
      if (this.listConfirm.length == 0) {
        this.$message({
          message: "Tất cả học sinh đều sai thông tin, không thể tạo hóa đơn",
          type: "error",
          duration: 5000
        });
        this.loadingImport = false
        this.isContinue = false;
      } else {
        this.dialogFormVisible = true
        this.loadingImport = false
      }
    },

    handleUpload(file, fileList) {
      this.fileList = fileList
      this.showUploadButton = fileList.length > 0;
      if (!this.fileList || this.fileList.length === 0) {
        console.error("Không có file được chọn");
        return;
      }

    },

    /**
     * Xử lý khi ô input nhận được file khi upload, nhận các thông báo hiển thị lên web
     * @param event
     */
    handleExcelImport() {
      this.loadingImport = true
      if (this.turn == null) {
        this.$message({
          message: "Bạn chưa chọn đợt thu",
          type: "error",
        });
        this.loadingImport = false
      } else {
        if (this.listDay !== null) {
          if (this.listDay.length !== 0) {
            this.startDateExcel = moment(this.listDay[0]).format('YYYY-MM-DD')
            this.endDateExcel = moment(this.listDay[1]).format('YYYY-MM-DD')
          }
        }
        if (this.startDateExcel == null && this.endDateExcel == null) {
          this.$message({
            message: "Bạn chưa chọn ngày bắt đầu và ngày kết thúc đợt thu",
            type: "error",
            duration: 5000
          });
          this.loadingImport = false
        } else if (this.monthAndYearUpload === '' || this.monthAndYearUpload === null) {
          this.$message({
            message: "Bạn chưa chọn tháng",
            type: "error",
            duration: 5000
          });
          this.loadingImport = false
        } else {
          for (const file of this.fileList) {
            this.excelFile = file.raw;
          }
          const formDataExcel = new FormData();
          formDataExcel.append("file", this.excelFile);
          formDataExcel.append("collectionTurn", this.turn.toString());
          formDataExcel.append("descriptionTurn", this.form.descriptionTurn);
          formDataExcel.append("month", this.month.toString());
          formDataExcel.append("createdYear", this.year.toString());
          formDataExcel.append("startDateExcel", this.startDateExcel);
          formDataExcel.append("endDateExcel", this.endDateExcel);
          formDataExcel.append("groupNameCurrent", this.groupNameCurrent);
          formDataExcel.append("idGroup", this.dataSearch.idGroup);
          groupExcelService
              .importExcelFileForGroup(formDataExcel)
              .then((res) => {
                if (res.data[0].notifyStructureTable === "false") {
                  this.$message({
                    message: "File của bạn không đúng cấu trúc, vui lòng kiểm tra lại!",
                    type: "error",
                    duration: 5000
                  });
                  this.loadingImport = false
                } else {
                  let listStudentNotInCurrentGroup = [];
                  for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].notifyNotInGroup === "false") {
                      listStudentNotInCurrentGroup.push(res.data[i].name)
                    }
                  }
                  if (listStudentNotInCurrentGroup.length !== 0) {
                    if (listStudentNotInCurrentGroup.length > 10) {
                      let message = "File Excel tải lên có nhiều học sinh không thuộc nhóm hiện tại." + "<br>" + "Vui lòng kiểm tra lại!"
                      this.$message({
                        showClose: true,
                        message: message,
                        dangerouslyUseHTMLString: true,
                        type: "error",
                        duration: 10000
                      });
                      this.loadingImport = false
                    } else {
                      let message = "Các học sinh có tên: " + "<br>" + "<br>"
                      for (let i = 0; i < listStudentNotInCurrentGroup.length; i++) {
                        message += (i + 1) + "-" + listStudentNotInCurrentGroup[i] + "<br>"
                      }
                      message = message + "<br>" + "Không thuộc nhóm hiện tại, vui lòng kiểm tra lại!"
                      this.$message({
                        showClose: true,
                        message: message,
                        dangerouslyUseHTMLString: true,
                        type: "error",
                        duration: 10000
                      });
                      this.loadingImport = false
                    }
                  } else {
                    if (res.data[0].notifyNoChangeNameKt == "false") {
                      this.$message({
                        showClose: true,
                        message: "Bạn chưa thay đổi tên khoản." + "<br>" + "Vui lòng sửa lại tên khoản khác với mẫu mặc định của hệ thống!",
                        dangerouslyUseHTMLString: true,
                        type: "error",
                        duration: 5000
                      });
                      this.loadingImport = false
                    } else {
                      if (res.data[0].notifySkipNameKt == "false") {
                        this.$message({
                          showClose: true,
                          message: "Bạn đã không nhập tên khoản theo đúng thứ tự trong file Excel." + "<br>" + "Vui lòng nhập lại tên khoản!",
                          dangerouslyUseHTMLString: true,
                          type: "error",
                          duration: 5000
                        });
                        this.loadingImport = false
                      } else {
                        if (res.data[0].listDuplicateNamePayment.length != 0) {
                          let listDuplicate = res.data[0].listDuplicateNamePayment
                          let message = "Các khoản có tên: " + "<br>" + "<br>"
                          for (let i = 0; i < listDuplicate.length; i++) {
                            message += (i + 1) + "-" + listDuplicate[i] + "<br>"
                          }
                          message = message + "<br>" + " Bị trùng, vui lòng kiểm tra lại!"
                          this.$message({
                            showClose: true,
                            message: message,
                            dangerouslyUseHTMLString: true,
                            type: "error",
                            duration: 10000
                          });
                          this.loadingImport = false
                        } else {
                          let listInformation = res.data
                          for (let i = 0; i < listInformation.length; i++) {
                            if (listInformation[i].notifyInfo == "false") {
                              this.listError.push(listInformation[i])
                            } else {
                              this.listConfirm.push(listInformation[i])
                            }
                          }
                          this.loadingImport = false
                          if (this.listError == 0) {
                            this.dialogFormVisible = true;
                            this.dialogFailInformation = false;
                          } else {
                            this.dialogFailInformation = true;
                            this.dialogFormVisible = false;
                          }
                        }
                      }

                    }
                  }
                }
              }).catch(() => {
            this.$message.error('Không đọc được file Excel')
            this.loadingImport = false
          });
        }
      }
    },

    /**
     * Nhận collectionTurn và tháng từ Component cha (KidsSearchTable)
     */
   async getCheckDataInitialForGroup(groupName) {
      this.monthAndYearUpload = this.dataSearch.monthAndYear
      await this.handelChangeDate(this.monthAndYearUpload)
      if (this.collectionTurn > 0){
        this.turn = 1
        await this.showStartAndEndDate()
      }else {
        this.turn = null
        this.monthAndYearUpload = ''
      }
      this.groupNameCurrent = groupName
    },

    /**
     *  Phương thức được gọi mỗi khi có sự thay đổi trong các hàng được chọn
     * @param val
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    /**
     * Lấy lượt thu
     */
    getCollectionTurn() {
      this.dataSearch.month = this.month
      this.dataSearch.createdYear = this.year
      this.dataSearch.monthAndYear = this.monthAndYearUpload
      this.$store.dispatch("finance2Module/init_kids_data", this.dataSearch)
      groupExcelService.getCollectionTurn(
          this.month,
          this.dataSearch.idGroup,
          this.year
      )
          .then((res) => {
            this.collectionTurn = res.data;
            this.$emit('refreshCollectionTurn', this.collectionTurn)
          });
    },


    /**
     * Mở input nhập mô tả đợt thu
     */
    handleOpenInputDescriptionTurn() {
      if (this.collectionTurn > 0){
        this.$confirm('Tháng bạn chọn đã tồn tại đợt thu! <br> Bạn có muốn tạo thêm đợt thu khác không?',"Cảnh báo!",{
          confirmButtonText: 'Có',
          cancelButtonText: 'Không',
          type: 'warning',
          dangerouslyUseHTMLString: true
        }).then(() => {
          this.startDateExcel = null
          this.endDateExcel = null
          this.listDay = []
          this.isInputDescriptionTurn = true
          this.turn = this.form.collectionTurn;
          this.collectionTurn = this.turn
        })
      }else {
        this.startDateExcel = null
        this.endDateExcel = null
        this.listDay = []
        this.isInputDescriptionTurn = true
        this.turn = this.form.collectionTurn;
        this.collectionTurn = this.turn
      }

    },
    /**
     * Nhấn nút Import, lưu dữ liệu vào DB
     */
    async handleClickImportButton() {
      this.loadingImport = true
      this.paramSaveExcelToDB = []
      let listMinus = []
      this.multipleSelection.forEach((x) => {
        let param = {
          kidCode: String,
          month: Number,
          year: Number,
          statusSaveAttendance: Boolean,
          statusSavePayment: Boolean,
          statusSaveMoney: Boolean,
          statusSaveNote: Boolean,
          statusTransactionEntry: String,
          statusAddMoneyFirst: String,
          idGroup: Number,

        }
        if (x.notifyMinusPayment === "true" && x.notifyMinusAttendance === "true") {
          param.kidCode = x.kidCode
          param.month = this.month
          param.year = this.year
          param.statusSaveAttendance = this.overwriteAttendance
          param.statusSavePayment = this.overwritePayment
          param.statusSaveMoney = this.overwriteMoney
          param.statusSaveNote = this.overwriteNote
          param.statusTransactionEntry = this.statusTransactionEntry
          param.statusAddMoneyFirst = this.updateMoney
          param.idGroup = this.dataSearch.idGroup
          this.paramSaveExcelToDB.push(param)
        }
        if (x.notifyMinusPayment === "false" || x.notifyMinusAttendance === "false") {
          listMinus.push(x)
        }
      });
      if (this.multipleSelection.length < 1) {
        this.$message({
          message: "Bạn chưa chọn học sinh nào",
          type: "error",
        });
        this.loadingImport = false
      } else if (this.overwritePayment === true && this.multipleSelection.length !== this.listConfirm.length && this.checkUpdateMoney === true) {
        this.$message({
          message: "Đề nghị chọn tất cả học sinh để cập nhật mới toàn bộ dữ liệu!",
          type: "error",
        });
        this.loadingImport = false
      } else {
        if (listMinus.length > 0) {
          let messageErr = "Các học sinh: " + "<br>" + "<br>"
          for (let i = 0; i < listMinus.length; i++) {
            messageErr += (i + 1) + "-" + listMinus[i].name + "<br>"
          }
          messageErr = messageErr + "<br>" + "Thông tin điểm danh hoặc khoản thu bị âm, vui lòng kiểm tra lại!"
          this.$message({
            message: messageErr,
            type: "error",
            dangerouslyUseHTMLString: true,
            duration: 5000
          });
          this.loadingImport = false
        } else {
          try {
            await this.$confirm("Bạn có chắc chắn Upload File", "Thông báo!", {
              distinguishCancelAndClose: true,
              closeOnClickModal: false,
              confirmButtonText: "Có",
              cancelButtonText: "Không",
            });

            await groupExcelService.saveDataFromExcelToDBForGroup(this.paramSaveExcelToDB).then(() => {
              this.$message({
                message: "Upload File thành công",
                type: "success",
              });

            }).then(() => {
              this.handleBeforeUploadSuccess();
              this.handleClose()
            });


          } catch (error) {
            this.$message({
              message: "Upload File thất bại",
              type: "error",
            });
          } finally {
            this.loadingImport = false;
          }
        }
      }
    },

    /**
     * Before Import Success
     */
    handleBeforeUploadSuccess() {
      this.dialogFormVisible = false
      this.$emit("closeCheckerDialog", false);
      this.getCollectionTurn()
      this.isInputDescriptionTurn = false
    },

    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: semi-bold;";
    },
    handleClose() {
      this.monthAndYearUpload = ''
      this.overwriteNote = true
      this.overwriteAttendance = true
      this.overwritePayment = true
      this.overwriteMoney = true
      this.multipleSelection = []
      this.listDay = []
      this.isInputDescriptionTurn = false
      this.form.descriptionTurn = ""
      this.turn = null
      this.showUploadButton = false;
      this.listError = [];
      this.listConfirm = [];
      this.dialogFormVisible = false
      this.dialogFailInformation = false
      this.checkCreateMoney = true
      this.checkUpdateMoney = false
      this.updateMoney = "CREATE_MONEY"
      this.statusTransactionEntry = "MONEY_WALLET"
      this.$emit("closeCheckerDialog", false);
      this.$refs.upload.clearFiles();
    },
  },
};


</script>
<style lang="scss" scoped>
.button_method {
  text-align: left;
}

.input-common {
  margin-right: 5px;
}

.excel-upload-input {
  display: none;
}

.overwrite {
  margin-top: 5px;
}

.excel_upload {
  float: left;
  margin-right: 10px;
}

/deep/ .el-form-item__label {
  text-align: left;
}

.custom_button {
  display: flex;
  justify-content: right;
  margin-right: 10px;
  margin-top: 10px;
}

.custom_button_error {
  display: flex;
  justify-content: right;
  margin-top: 20px;
}
</style>
