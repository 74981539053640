<template>
  <div>
    <div class="search-table">
      <el-date-picker
          style="width: 115px"
          class="custom-date-picker"
          :clearable="false"
          v-model="monthAndYear"
          type="month"
          @change="handelUpdateDate"
          placeholder="Chọn tháng"
          value-format="M-yyyy"
          format="MM-yyyy"
          :time-arrow-control="false"
      >
      </el-date-picker>
      <!-- chọn trạng thái -->
      <el-select
          @change="handleChangeKidStatus"
          style="width: 120px"
          class="input-common"
          v-model="dataSearch.kidStatus"
          placeholder="Trạng thái"
      >
        <el-option
            v-for="item in kidStatusListExcel"
            :key="item.key"
            :value="item.key"
            :label="item.value"
        ></el-option>
      </el-select>
      <!-- chọn nhóm -->
      <el-select
          @change="handleChangeKidGroup"
          class="input-common input-grade"
          style="width: 203px"
          v-model="dataSearch.idGroup"
          placeholder="Chọn nhóm"
      >
        <el-option
            v-for="(item,index) in kidGroupList"
            :key="index"
            :value="item.id"
            :label="item.nameGroup"
        ></el-option>
      </el-select>
      <el-select
          @change="handleChangeMoneyStatus"
          @remove-tag="handleChangeMoneyStatus"
          @clear="handleChangeMoneyStatus"
          style="width: 203px"
          v-model="statusList"
          placeholder="Chọn trạng thái"
          multiple
          collapse-tags
          clearable
      >
        <el-option
            v-for="(item,index) in  statusOrderList"
            :key="index"
            :value="item"
            :label="item"
        ></el-option>
      </el-select>

      <el-input
          @clear="handleClearKidName"
          clearable
          class="input-common input-class"
          style="width: 240px"
          placeholder="Nhập tên, biệt danh"
          v-model="kidName"
          @keyup.enter.native="handleChangeKidName"
      >
        <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleSearchResultByName"
        ></el-button>
      </el-input>
      <el-select
          @change="handleChangeCollectionTurn"
          style="width: 100px"
          class="input-common"
          v-model="dataSearch.collectionTurn"
          placeholder="Đợt thu"
      >
        <el-option
            v-for="item in collectionTurn"
            :key="item"
            :value="item"
            :label="`Đợt ${item}`"
        ></el-option>
      </el-select>
      <el-button @click="additionFilter">
        <i
            class="el-icon-circle-plus"
            :style="{color: showAddFilterColor()}"
        ></i>
      </el-button>
      <el-button v-if="checkFilter()"
                 @click="resetFilterDefault()"
                 :style="{color: showResetFilerColor()}">
        <i
            class="el-icon-refresh"
        >
        </i>
      </el-button>
      <span class="loading_title" v-if="loadingBill === true" style="color: red;	text-align: right;">Dữ liệu đang được xử lý... <span
          v-loading="loadingBill"></span> </span>
      <span class="loading_title" v-if="loadingUpdate === true">Đang cập nhật điểm danh... <span
          v-loading="loadingUpdate"></span></span>
      <span class="loading_title" v-if="bulkPayments === true">Đang cập nhật thanh toán toàn bộ... <span
          v-loading="bulkPayments"></span></span>
      <el-button
          style="float: right"
          class="custom_button"
          type="success"
          icon="el-icon-plus"
          size="medium"
          v-if="checkRevenue"
          @click="handleOpenAddNameExpensesDialog"
      >
        Thêm khoản
      </el-button>
    </div>
    <slot></slot>
    <el-row style="margin-top: 15px ">
      <el-col :span="20" :offset="4" style=" display: inline-flex;justify-content: right ">
        <el-pagination
            style=" margin-right: 5.2rem;margin-left: auto"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPagePagination"
            :page-sizes="listSizeLimit"
            :page-size="sizeLimit"
            layout=" prev, pager,sizes, next"
            :total="sizeLimit * totalPages"
        >
        </el-pagination>
        <el-menu
            style="margin-right: 1.2rem"
            v-if="checkPermission(['fees_2_excel_operation'])"
            background-color="#67C23A"
            text-color="#FFFFFF"
            active-text-color="#FFFFFF"
            :unique-opened="true"
            menu-trigger="hover"
            mode="horizontal"
            @select="handleCommand"
        >
          <el-submenu index="1">
            <template slot="title">Tác vụ</template>
            <el-menu-item index="updateAttendanceData" v-if="updateAttendanceForGroup === true">Cập nhật điểm danh
            </el-menu-item>
            <el-submenu index="2">
              <template slot="title">Hóa đơn</template>
              <el-menu-item index="2-createInvoice">Khởi tạo khoản thu</el-menu-item>
              <el-menu-item index="2-pdfPreview">In hóa đơn 1 liên</el-menu-item>
              <el-menu-item index="2-pdfSplit">In hóa đơn 2 liên</el-menu-item>
              <el-menu-item index="2-exportAllBill"
              >Xuất hóa đơn theo tháng
              </el-menu-item
              >
              <el-menu-item index="2-deleteOrder">Hủy hóa đơn</el-menu-item>
              <el-menu-item v-if="listHiddenOrderKids" index="2-openOrder">Khôi phục hóa đơn</el-menu-item>
              <el-menu-item v-if="listHiddenOrderKids" index="2-destroyOrder">Xóa hóa đơn</el-menu-item>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">Xuất Excel tổng hợp</template>
              <el-menu-item index="3-exportExcel">Theo đợt đã chọn</el-menu-item>
              <el-menu-item index="3-exportAllExcel">Theo tất cả các đợt</el-menu-item>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">Gửi học phí</template>
              <el-menu-item index="4-sendApp">Gửi qua Onekids</el-menu-item>
              <el-menu-item index="4-sendSMS">Gửi SMS</el-menu-item>
            </el-submenu>
            <el-submenu index="5">
              <template slot="title">Mẫu học phí</template>
              <el-menu-item index="5-uploadTemplateSchool">Upload File mẫu</el-menu-item>
              <el-menu-item index="5-deleteTemplateSchool">Xóa File mẫu</el-menu-item>
              <el-menu-item index="5-instructUploadFileSample">Hướng dẫn sử dụng</el-menu-item>
            </el-submenu>
            <el-menu-item index="showListForApp">Hiển thị</el-menu-item>
            <el-menu-item index="hiddenListForApp">Bỏ hiển thị</el-menu-item>
            <el-menu-item index="createNoteExcel">Tạo ghi chú</el-menu-item>
            <el-menu-item index="allCashier">Thanh toán hàng loạt</el-menu-item>
          </el-submenu>
        </el-menu>
        <el-button
            v-if="checkPermission(['fees_2_excel_export_template'])"
            style="font-family: Roboto,serif"
            type="success"
            icon="el-icon-link"
            size="medium"
            @click="handleOpenExportTempDialog"
        >
          Xuất mẫu học phí
        </el-button>

        <el-button
            v-if="checkPermission(['fees_2_excel_import'])"
            style="font-family: Roboto,serif"
            type="success"
            icon="el-icon-link"
            size="medium"
            @click="handleImportCheckerForGroup"
        >
          Nhập từ Excel
        </el-button>
      </el-col>
    </el-row>
    <addition-filter :dialog-visible="showAdditionFilter"
                     ref="AdditionFilter"
                     @dialog-close="handleCloseAddition"/>
    <!--  dialog field  -->
    <UploadTemplateSchoolForGroup
        :kidGroupList="kidGroupList"
        :idGroup="dataSearch.idGroup.toString()"
        :isOpenUploadTemplateSchool="openUploadTemplateSchool"
        @close="handleCloseUploadTemplateSchool"
    />

    <DeleteTemplateSchoolForGroupDialog
        :is-dialog-template-visible="isOpenDeleteTemplate"
        @closeTemplateDeleteDialog="handleCloseTemplateDeleteDialog"
    />

    <ExportTemplateForGroupDialog
        :isOpenExportTemplateDialog="isOpenExportTemplateDialog"
        @closeExportTemplateDialog="handleCloseExportTemplateDialog"
    />

    <CreateNoteForGroupDialog
        :isOpenCreateNote="createNote"
        @closeNoteDialog="handleCloseNoteDialog"
    />

    <SendAppForGroupDialog
        :dialog-visible-ex="dialogVisibleEx"
        @close="handleCloseSendApp"
        ref="SendAppDialog"
    />

    <KidExcelImportCheckerForGroup
        :isOpenImportChecker="isOpenExcelChecker"
        ref="checkDataBeforeUploadDialog"
        @closeCheckerDialog="handleCloseImportChecker"
        @refreshCollectionTurn="handleRefreshCollectionTurn"
    />

    <AllCashierForGroup
        :isOpenAllCashierDialog="showAllCashierDialog"
        :bulkPayments.sync="bulkPayments"
        @dialog-close="closeAllCashierDialog"
        ref="AllCashierForGroup"
    />

    <!--    Thêm khoản thu-->
    <AddNameExpensesGroup
        :isOpenAddNameExpensesDialog="showAddNameExpensesDialog"
        @close="closeAddNameExpensesDialog"
    />

    <!-- Cường thêm dialog chọn cách xuất excel-->
    <ExportTuitionFormDialog
        :isOpenExportTuitionFormDialog="isOpenExportTuitionFormDialog"
        @closeExportTuitionFormDialog="handleCloseExportTuitionFormDialog"
        :is-export-all-excel="isExportAllExcel"
        @update:monthAndYear="updateMonthAndYear"
    />
  </div>
</template>

<script>
import moment from "moment/moment";
import checkPermission from "@/utils/permission";
import {v4} from "uuid";
import {mapState} from "vuex";
import {kidStatusListExcel} from "@/constant/listCommon";
import updateAttendanceDataMixin from "@/global/mixin_group/updateAttendanceData.mixin";
import exportExcelMixin from "@/global/mixin_group/exportExcel.mixin";
import sendSMSMixin from "@/global/mixin_group/sendSMS.mixin";
import exportBillMixin from "@/global/mixin_group/exportBill.mixin";
import listOderForAppMixin from "@/global/mixin_group/listOderForApp.mixin";
import deleteOrderMixin from "@/global/mixin_group/deleteOrder.mixin";
import UploadTemplateSchoolForGroup
  from "@/components/FinanceVer2/ByGroup/TableSearchGroupDialog/UploadTemplateSchoolForGroup.vue";
import DeleteTemplateSchoolForGroupDialog
  from "@/components/FinanceVer2/ByGroup/TableSearchGroupDialog/DeleteTemplateSchoolForGroupDialog.vue";
import ExportTemplateForGroupDialog
  from "@/components/FinanceVer2/ByGroup/TableSearchGroupDialog/ExportTemplateForGroupDialog.vue";
import CreateNoteForGroupDialog
  from "@/components/FinanceVer2/ByGroup/TableSearchGroupDialog/CreateNoteForGroupDialog.vue";
import SendAppForGroupDialog from "@/components/FinanceVer2/ByGroup/TableSearchGroupDialog/SendAppForGroupDialog.vue";
import AdditionFilter from "@/components/FinanceVer2/ByGroup/TableSearchGroupDialog/AdditionFilter.vue";
import GroupExcelService from "@/services/Finance_Kids_V2/GroupExcelService";
import groupExcelService from "@/services/Finance_Kids_V2/GroupExcelService";
import KidExcelImportCheckerForGroup
  from "@/components/FinanceVer2/ByGroup/TableSearchGroupDialog/KidExcelImportCheckerForGroup.vue";
import AllCashierForGroup from "@/components/FinanceVer2/ByGroup/GroupExcelTableDialog/AllCashierForGroup.vue";
import AddNameExpensesGroup from "@/components/FinanceVer2/ByGroup/TableSearchGroupDialog/AddNameExpensesGroup.vue";
import ExportTuitionFormDialog from "@/components/FinanceVer2/ByClass/TableSearchDialog/ExportTuitionFormDialog";
import NotifyMessage from "@/commonFuncion/NotifyMessage";
import CommonFunction from "@/commonFuncion/CommonFunction";


export default {
  name: "KidsTableSearchGroup",
  components: {
    AddNameExpensesGroup,
    AllCashierForGroup,
    KidExcelImportCheckerForGroup,
    SendAppForGroupDialog,
    CreateNoteForGroupDialog,
    ExportTemplateForGroupDialog,
    DeleteTemplateSchoolForGroupDialog,
    UploadTemplateSchoolForGroup,
    AdditionFilter,
    ExportTuitionFormDialog
  },
  watch: {
    isOrderDelete: {
      handler() {
        this.isUpdateCollectionTurn = false //không cho phép gọi update đợt thu mới nhất khi thao tác ẩn hóa đơn, giữ lại đợt thu hiện tại
        this.getStatus();

      },
      deep: true
    },
    isOrderOpen: {
      handler() {
        this.isUpdateCollectionTurn = false //không cho phép gọi update đợt thu mới nhất khi thao tác ẩn hóa đơn, giữ lại đợt thu hiện tại
        this.getStatus();

      },
      deep: true
    },
    isOrderDestroy: {
      handler() {
        this.isUpdateCollectionTurn = false //không cho phép gọi update đợt thu mới nhất khi thao tác ẩn hóa đơn, giữ lại đợt thu hiện tại

        this.getStatus();

      },
      deep: true
    },
    // gọi nếu cần cập nhật trạng thái
    updateStatus: {
      handler() {
        this.isUpdateCollectionTurn = false
        this.getStatus();
      },
      deep: true,
    },
    monthAndYear: {
      handler(val) {
        this.dataSearch.month = val.split('-')[0]
        this.dataSearch.createdYear = val.split('-')[1]

      }
    }
  },
  computed: {
    ...mapState("groupExcelModule", [
      "totalPages",
      "idKidList",
      "listNullBillKids",
      "listHiddenOrderKids",
      "listShowForApp",
      "updateStatus",
      "messageShowForApp",
      "messageHiddenForApp",
      "messageHiddenForApp",
      "responseFilterList", "checkRevenue","checkResponse"
    ]),
  },
  mixins: [
    exportExcelMixin,
    sendSMSMixin,
    exportBillMixin,
    listOderForAppMixin,
    deleteOrderMixin,
    updateAttendanceDataMixin,
  ],
  created() {
    this.fetchDataTable();
    this.$store.commit("groupExcelModule/renew_bill_preview");
    this.dataSearch.monthAndYear = this.monthAndYear
  },
  data() {
    return {
      isUpdateCollectionTurn: true,
      monthAndYear: moment().format('M-yyyy'),
      // phân trang
      currentPagePagination: 1,
      // tên học sinh
      kidName: "",
      kidGroupList: [],
      kidStatusListExcel,
      // gradeList: [],
      classList: [],
      statusList: [],
      statusOrderList: [],
      flag: false,
      sizeLimit: 30, // mặc định là  giới hạn 30 bản ghi
      listSizeLimit: [30, 40, 50, 100, 300, 500, 1000],
      collectionTurn: 0,
      //Export Excel for month param
      previewExcelParam: {
        guiId: "success",
        date: "",
        idKidList: "",
        collectionTurn: 0,
        idGroup: ''
      },
      dataSearch: {
        monthAndYear: '',
        collectionTurn: null, // đợt thu
        month: moment().format('M'),
        kidStatus: "STUDYING",
        idGroup: "",
        idGrade: "",
        idClass: "",
        name: "", // tên học sinh
        page: 1,
        limit: 30,
        statusList: [],
        createdYear: moment().format("YYYY"),
      },
      // dialog field
      showAdditionFilter: false,
      isOpenExportTemplateDialog: false,
      isOpenExportExcelByMonthDialog: false,
      isOpenExcelChecker: false,
      isOpenHistoryOrderKids: false,
      openUploadTemplateSchool: false,
      createNote: false,
      dialogVisibleEx: false,
      isOpenDeleteTemplate: false,
      showListForApp: {
        date: "",
        idKidList: "",
        collectionTurn: "",
        idGroup: ''
      },
      exportExcelAll: {
        month: moment().format('M'),
        idGrade: "",
        idClass: "",
        year: moment().format("YYYY"),
        guiId: ""
      },
      showAllCashierDialog: false,
      bulkPayments: false,
      showAddNameExpensesDialog: false,
      isOpenExportTuitionFormDialog: false,
      isExportAllExcel: false,

    };
  },
  methods: {

    checkPermission,
    updateMonthAndYear(monthAndYear) {
      this.dataSearch.monthAndYear = monthAndYear;
    },
    handleCloseExportTuitionFormDialog(status) {
      this.isOpenExportTuitionFormDialog = status;
    },
    // lấy lượt thu theo ngày
    handelUpdateDate() {
      this.$store.commit("groupExcelModule/loading_data");
      this.$store.commit("groupExcelModule/setCheckRevenue");
      this.dataSearch.page = 1;
      this.currentPagePagination = 1;
      this.isUpdateCollectionTurn = true;
      this.dataSearch.monthAndYear = this.monthAndYear;
      this.getStatus();
    },
    // xử lý đổi trạng thái HS
    handleChangeKidStatus() {
      this.$store.commit("groupExcelModule/loading_data");
      this.dataSearch.page = 1;
      this.currentPagePagination = 1;
      this.$store.dispatch("groupExcelModule/init_kids_data", this.dataSearch);
    },
    // lấy lượt thu
    getCollectionTurn() {
      GroupExcelService
          .getCollectionTurn(
              this.dataSearch.month,
              this.dataSearch.idGroup,
              this.dataSearch.createdYear
          )
          .then((res) => {
            if (res.data === 0) {
              this.dataSearch.collectionTurn = null;
              this.collectionTurn = null;
              this.$store.commit("groupExcelModule/loading_data")
              this.$store.dispatch("groupExcelModule/init_kids_data", this.dataSearch);
              return;
            }
            this.collectionTurn = res.data;
            this.dataSearch.collectionTurn = this.collectionTurn;
            this.$store.commit("groupExcelModule/loading_data")
            this.$store.dispatch("groupExcelModule/init_kids_data", this.dataSearch);
          }).catch(() =>{
        this.$message({
          message: "Lỗi dữ liệu!",
          type: "error",
          duration: 5000
        });
        this.$store.commit("groupExcelModule/check_response",false)
      });
    },
    // bắt sự kiện tìm theo tên qua enter
    handleChangeKidName() {
      this.$store.commit("groupExcelModule/loading_data");
      this.currentPagePagination = 1;
      this.dataSearch.page = 1;
      this.dataSearch.name = this.kidName;
      this.$store.dispatch("groupExcelModule/init_kids_data", this.dataSearch);
    },

    // Trả ra kết quả tìm kiếm theo tên khi bấm vào icon tìm kiếm
    handleSearchResultByName() {
      this.$store.commit("groupExcelModule/loading_data");
      this.dataSearch.page = 1;
      this.currentPagePagination = 1;
      this.dataSearch.name = this.kidName;
      this.$store.dispatch("groupExcelModule/init_kids_data", this.dataSearch);
    },
    // tìm kiếm khi xóa tên học sinh
    handleClearKidName() {
      this.$store.commit("groupExcelModule/loading_data");
      this.currentPagePagination = 1;
      this.dataSearch.page = 1;
      this.dataSearch.name = this.kidName;
      this.$store.dispatch("groupExcelModule/init_kids_data", this.dataSearch);
    },
    handleChangeCollectionTurn() {
      this.$store.commit("groupExcelModule/loading_data");
      this.currentPagePagination = 1;
      this.dataSearch.page = 1;
      this.$store.dispatch("groupExcelModule/init_kids_data", this.dataSearch);
    },
    /**
     Xử lý phân trang
     */
    handleSizeChange(value) {
      this.$store.commit("groupExcelModule/loading_data")
      this.currentPagePagination = 1;
      this.dataSearch.page = 1;
      this.sizeLimit = value;
      this.dataSearch.limit = value;
      this.$store.dispatch("groupExcelModule/init_kids_data", this.dataSearch);
    },
    handleCurrentChange(value) {
      this.$store.commit("groupExcelModule/loading_data")
      this.currentPagePagination = value;
      this.dataSearch.page = value;
      this.$store.dispatch("groupExcelModule/init_kids_data", this.dataSearch);
    },
    // lấy danh sách trươc khi lấy ra table
    fetchDataTable() {
      this.getAllGroup()
    },
    /**
     * tìm tất cả các nhóm trong một trường
     */
    getAllGroup() {
      GroupExcelService.getListKidsGroup().then((res) => {
        this.kidGroupList = res.data
        this.dataSearch.idGroup = this.kidGroupList[0].id
        this.getStatus();
      }).catch(() => {
        this.$message({
          message: "Lỗi dữ liệu!",
          type: "error",
          duration: 5000
        });
        this.$store.commit("groupExcelModule/check_response",false)
      })
    },
    handleChangeKidGroup() {
      this.dataSearch.page = 1;
      this.currentPagePagination = 1;
      this.isUpdateCollectionTurn = true
      this.getStatus();
      this.$store.dispatch("groupExcelModule/init_kids_data", this.dataSearch);
    },
    handleChangeKidClass() {
      this.$store.commit("groupExcelModule/loading_data")
      this.isUpdateCollectionTurn = true
      this.getStatus();
    },
    handleChangeStatus() {
      this.$store.dispatch("groupExcelModule/init_kids_data", this.dataSearch);
    },
    getStatus() {
      const statusParam = {
        month: this.dataSearch.month,
        idGroup: this.dataSearch.idGroup,
        createdYear: this.dataSearch.createdYear
      }
      GroupExcelService
          .listStatusMoney(
              statusParam
          )
          .then((res) => {
            this.statusOrderList = res.data;
            if (!this.isUpdateCollectionTurn) {
              this.$store.dispatch(
                  "groupExcelModule/init_kids_data",
                  this.dataSearch
              );
              return;
            } else {
              this.getCollectionTurn();
            }
          }).catch(() =>{
        this.$message({
          message: "Lỗi dữ liệu!",
          type: "error",
          duration: 5000
        });
        this.$store.commit("groupExcelModule/check_response",false)
      });
    },
    handleChangeMoneyStatus() {
      this.flag = false
      if (2 <= this.statusList.length) {
        for (let i = 0; i < this.statusList.length; i++) {
          if (this.statusList[i] === "Chưa có hóa đơn") {
            this.flag = true;
            break;
          }
        }
      }
      if (this.flag === false) {
        this.$store.commit('groupExcelModule/loading_data');
        this.dataSearch.page = 1;
        this.currentPagePagination = 1;
        this.dataSearch.statusList = this.statusList;
        this.$store.dispatch("groupExcelModule/init_kids_data", this.dataSearch);
      } else {
        this.$message.error({
          showClose: true,
          message: "Không thể chọn Chưa có hóa đơn cùng với các trạng thái khác. <br>" +
              "Vui lòng chọn lại!",
          dangerouslyUseHTMLString: true,
        });
      }
    },
    // mở dialog import
    handleImportCheckerForGroup() {
      this.isOpenExcelChecker = !this.isOpenExcelChecker;
      let groupName = ""
      for (let i = 0; i < this.kidGroupList.length; i++) {
        if (this.kidGroupList[i].id === this.dataSearch.idGroup) {
          groupName = this.kidGroupList[i].nameGroup
        }
      }
      this.$refs.checkDataBeforeUploadDialog.getCheckDataInitialForGroup(groupName);
    },
    // đóng thẻ import
    handleCloseImportChecker(status) {
      this.isOpenExcelChecker = status;
    },
    // refresh lại đợt thu sau khi nhâpk
    handleRefreshCollectionTurn() {
      this.monthAndYear = this.dataSearch.monthAndYear
      this.handelUpdateDate()
    },
    handleCommand(command) {
      // gán giá trị
      const guiId = v4();
      const date = this.$funcCommon.formatMY(this.dataSearch.createdYear, this.dataSearch.month)
      const month = this.dataSearch.month;
      const idKidList = this.idKidList;
      const collectionTurn = this.dataSearch.collectionTurn;
      const dataSearch = this.dataSearch;
      const showListForApp = this.showListForApp;
      const idGroup = this.dataSearch.idGroup;
      if (command === '5-instructUploadFileSample') {
        this.instructUploadFileSample(guiId)
        return;
      }
      if (command === "5-uploadTemplateSchool") {
        this.handOpenTemplateSchoolDialog();
        return;
      }
      if (command === '5-deleteTemplateSchool') {
        this.isOpenDeleteTemplate = !this.isOpenDeleteTemplate
        return;
      }
      if (command === "2-exportAllBill") {
        this.exportBillAllByMonth(date, idGroup, guiId);
        return;
      }
      if (command === "3-exportAllExcel") {
        this.isOpenExportTuitionFormDialog = true;
        // this.exportExcelAllByMonth(month, this.dataSearch.createdYear, idGroup, guiId)
        return;
      }
      if (command === "updateAttendanceData") {
        this.updateAttendanceDataForGroup(idGroup, month, this.dataSearch.createdYear, this.dataSearch)
        return;
      }
      if (this.idKidList === "") {
        this.$message.error({
          showClose: true,
          message: "Chưa có học sinh nào được chọn",
          dangerouslyUseHTMLString: true,
        });
        return;
      }
      if (this.listHiddenOrderKids) {
        if (command === "2-openOrder") {
          this.openOrder(idKidList, dataSearch, showListForApp);
          return;
        }
        if (command === "2-destroyOrder") {
          this.destroyOrder(idKidList, dataSearch, showListForApp);
          return;
        }
        this.$message.error({
          showClose: true,
          message: `Học sinh:  ${this.listHiddenOrderKids} <br>Đã bị ẩn, không thực hiện được tác vụ`,
          dangerouslyUseHTMLString: true,
          duration: 5000
        });
        return;
      }
      if (this.dataSearch.collectionTurn !== null) {
        if (command === "3-exportExcel") {

          this.isOpenExportTuitionFormDialog = true;

          // this.handleExportExcelByMonth(guiId, date, idKidList, collectionTurn, idGroup);
          return;
        }
      }
      if (command === "2-createInvoice") {
        if (this.dataSearch.collectionTurn !== null) {
          const idKidListArray = this.idKidList.split(',').map(Number);
          let flag = false;
          for (let i = 0; i < idKidListArray.length; i++) {
            for (let j = 0; j < this.responseFilterList.length; j++) {
              if (idKidListArray[i] === this.responseFilterList[j].id && this.responseFilterList[j].status !== null) {
                flag = true;
                this.$message.error({
                  showClose: true,
                  message: `Chỉ thực hiện với học sinh chưa có hóa đơn!`,
                  dangerouslyUseHTMLString: true,
                  duration: 5000
                });
                return;
              }
            }
          }
          if (!flag) {
            const data = {
              idKidList: this.idKidList,
              date: date,
              collectionTurn: this.dataSearch.collectionTurn,
              idGroup: this.dataSearch.idGroup
            }
            groupExcelService.createInvoice(data).then(() => {
              this.$notify.success({
                title: "Thông báo",
                message: "Khởi tạo khoản thu thành công",
              });
              this.$store.dispatch("groupExcelModule/init_kids_data", this.dataSearch);
            }).catch((error) => {
              console.log(error)
            })
          }
          return;
        } else {
          this.$message.error({
            showClose: true,
            message: `Chỉ thực hiện với danh sách học sinh có đợt thu!`,
            dangerouslyUseHTMLString: true,
            duration: 5000
          });
          return;
        }
      }
      if (this.listNullBillKids) {
        this.$message.error({
          showClose: true,
          message: `Học sinh:  ${this.listNullBillKids}<br>Chưa có khoản, không thực hiện được tác vụ`,
          dangerouslyUseHTMLString: true,
          duration: 5000
        });
        return;
      }

      // kiểm soát xem có trường hợp k thể xuất hóa đơn k

      switch (command) {
        case "2-pdfPreview": {
          this.previewExcelParam.date = this.$funcCommon.formatMY(this.dataSearch.createdYear, this.dataSearch.month);
          this.previewExcelParam.idKidList = this.idKidList;
          this.previewExcelParam.collectionTurn =
              this.dataSearch.collectionTurn;
          this.previewExcelParam.idGroup = this.dataSearch.idGroup

          this.$store.dispatch(
              "groupExcelModule/preview_bill_data",
              {param: this.previewExcelParam, printStyle: 'pdfPreview'}
          );
          break;
        }
        case"2-pdfSplit": {
          this.previewExcelParam.date = this.$funcCommon.formatMY(this.dataSearch.createdYear, this.dataSearch.month);
          this.previewExcelParam.idKidList = this.idKidList;
          this.previewExcelParam.collectionTurn =
              this.dataSearch.collectionTurn;
          this.previewExcelParam.idGroup = this.dataSearch.idGroup

          this.$store.dispatch(
              "groupExcelModule/preview_bill_data",
              {param: this.previewExcelParam, printStyle: 'pdfSplit'}
          );
          break;
        }
        case "showListForApp": {
          this.showListOrderForApp(idKidList, dataSearch, showListForApp);
          break;
        }

        case "hiddenListForApp": {
          this.hiddenListOrderForApp(idKidList, dataSearch, showListForApp);
          break;
        }

        case "2-deleteOrder": {
          this.deleteOrder(idKidList, dataSearch, showListForApp);
          break;
        }

        case "createNoteExcel": {
          this.createNoteMethod();
          break;
        }
        case "4-sendSMS": {
          this.sendSMS(idKidList, date, collectionTurn, idGroup);
          break;
        }
        case "4-sendApp": {
          if (!this.idKidList) {
            this.$message.error({
              showClose: true,
              message: `Không có học sinh được chọn để hiển thị lên App`,
            });
            break;
          }
          if (this.messageHiddenForApp) {
            this.$message.error({
              showClose: true,
              message: `Danh sách học sinh chưa được hiển thị : <br>${this.messageHiddenForApp}`,
              dangerouslyUseHTMLString: true,
              duration: 5000,
            });
            break;
          }
          this.sendApp();
          break;
        }
        case "allCashier": {
          const idKidsListArray = this.idKidList.split(",").map(Number);
          let notActivatedList = this.responseFilterList.filter((x) => {
            return x.motherPhone === "" && x.fatherPhone === "";
          });
          let flag = false
          let kid = null;
          if (notActivatedList.length > 0) {
            idKidsListArray.forEach((y) => {
              kid = notActivatedList.find((x) => x.id === y);
            });
          }
          if (kid) {
            this.$message.error({
              showClose: true,
              message: "Học sinh " + kid.fullName + " chưa được kích hoạt! Hãy kích hoạt hoặc bỏ khỏi danh sách",
              duration: 5000,
            });
            break;
          }

          for (let i = 0; i < idKidsListArray.length; i++) {
            for (let j = 0; j < this.responseFilterList.length; j++) {
              if (idKidsListArray[i] === this.responseFilterList[j].id) {
                if (this.responseFilterList[j].sumMoney === 0) {
                  this.$message.error({
                    showClose: true,
                    message: `Học sinh chưa có khoản thu không thực hiện được tác vụ`,
                    dangerouslyUseHTMLString: true,
                    duration: 5000,
                  });
                  return;
                }
              }
              if (this.responseFilterList[j].status !== "Chưa đóng 100%") {
                flag = true;
                break;
              }
            }
          }

          if (!flag) {
            this.openAllCashierDialog();
            break;
          } else {
            this.$message.error({
              showClose: true,
              message: `Chỉ thực hiện với học sinh chưa đóng 100%!`,
              dangerouslyUseHTMLString: true,
              duration: 5000,
            });
            break;
          }
        }
        default:
          break;
      }
    },
    // đóng thẻ import
    handleCloseOpenHistoryOrderKids(status) {
      this.isOpenHistoryOrderKids = status;
    },
    //Mở component HistoryOrderKidsCode
    handleOpenHistoryOrderKids() {
      this.isOpenHistoryOrderKids = !this.isOpenHistoryOrderKids;
    },
    //Mở upload template nhà truờng
    handOpenTemplateSchoolDialog() {
      this.openUploadTemplateSchool = !this.openUploadTemplateSchool;
    },
    // đóng thẻ import
    handleOpenExportTempDialog() {
      if (this.idKidList.split(",").map(Number).indexOf(0) === 0) {
        this.$message({
          message: "Chưa có học sinh nào được chọn!",
          type: "error",
        });
      } else {
        this.isOpenExportTemplateDialog = true;
      }
    },

    handleCloseExportTemplateDialog(status) {
      this.isOpenExportTemplateDialog = status;
    },
    // Gửi qua App mở dialog
    sendApp() {
      this.dialogVisibleEx = !this.dialogVisibleEx;
    },
    handleCloseTemplateDeleteDialog(value) {
      this.isOpenDeleteTemplate = value
    },
    /**
     * Mở dialog hiển thị tạo ghi chú
     */
    createNoteMethod() {
      if (this.idKidList.split(",").map(Number).indexOf(0) === 0) {
        this.$message({
          message: "Chưa có học sinh nào được chọn!",
          type: "error",
        });
      } else {
        this.createNote = !this.createNote;
      }
    },
    handleCloseNoteDialog(close) {
      this.createNote = close;
    },
    handleCloseUploadTemplateSchool(status) {
      this.openUploadTemplateSchool = status;
    },
    handleCloseSendApp(close) {
      this.dialogVisibleEx = close;
    },
    additionFilter() {
      this.showAdditionFilter = true
    },
    handleCloseAddition() {
      this.showAdditionFilter = false
    },
    /**
     * Mở Dialog thanh toán hàng loạt
     */
    openAllCashierDialog() {

      let month = this.dataSearch.month;
      let collectionTurn = this.dataSearch.collectionTurn;
      this.showAllCashierDialog = true;
      this.$refs.AllCashierForGroup.getDataAllCashierInitialForGroup(
          month,
          collectionTurn,
          this.idKidList,
          this.dataSearch.idGroup
      );

    },
    closeAllCashierDialog() {
      this.showAllCashierDialog = false;
      this.$store.commit('groupExcelModule/get_status_excel')
    },

    // Khi chọn filter thì màu đỏ
    showAddFilterColor() {
      if (this.dataSearch.idClass !== '' ||
          this.dataSearch.idGrade !== '') {
        return 'red'
      }
    },

    // Reset filter
    resetFilterDefault() {
      this.statusList = []
      this.dataSearch.statusList = []
      this.dataSearch.idClass = ""
      this.dataSearch.idGrade = ""
      this.dataSearch.name = ""
      this.kidName = ""
      this.$refs.AdditionFilter.handleClear()
      this.$store.dispatch("groupExcelModule/init_kids_data", this.dataSearch);
    },

    checkFilter() {
      return this.dataSearch.idGrade !== "" ||
          this.dataSearch.idClass !== "" ||
          this.dataSearch.name !== "" ||
          this.statusList.length !== 0;
    },

    showResetFilerColor() {
      if (this.dataSearch.idGrade !== "" || this.dataSearch.idClass !== "" ||
          this.dataSearch.name !== "" || this.statusList.length !== 0) {
        return "red"
      }
    },
    // Mở dialog thêm khoản thu
    handleOpenAddNameExpensesDialog() {
      this.showAddNameExpensesDialog = true
    },
    closeAddNameExpensesDialog() {
      this.showAddNameExpensesDialog = false
    },

    //Quan trọng, ko đc xóa!
    xuatTheoDotThu(monthAndYear, selectedOption) {
      const guiId = v4();
      const date = this.$funcCommon.formatMY(this.dataSearch.createdYear, this.dataSearch.month)
      const idKidList = this.idKidList;
      const collectionTurn = this.dataSearch.collectionTurn;
      const idGroup = this.dataSearch.idGroup;
      const dateAttendance = CommonFunction.convertDate(monthAndYear);
      const status = !selectedOption;
      if (collectionTurn != null) {

        this.handleExportExcelByMonth(guiId, date, idKidList, collectionTurn, idGroup, status, dateAttendance);

      } else {
        NotifyMessage.notifyError("Không có dữ liệu học phí!")
      }
      this.isOpenExportTuitionFormDialog = false;

    },

    xuatTheoThang(monthAndYear, selectedOption) {
      const guiId = v4();
      const month = this.dataSearch.month;
      const idGroup = this.dataSearch.idGroup;
      const dateAttendance = CommonFunction.convertDate(monthAndYear);
      const status = !selectedOption;
      this.exportExcelAllByMonth(month, this.dataSearch.createdYear, idGroup, guiId, status, dateAttendance);
      this.isExportAllExcel = false;
      this.isOpenExportTuitionFormDialog = false;

    }
  },
}
</script>


<style lang="scss" scoped>

.search-table {
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
}

.search-table > * {
  margin-right: 5px; /* Khoảng cách 5px giữa các khối con */
}

.custom_button {
  font-family: Roboto, serif;
  font-size: 14px;
  margin-left: auto;
  margin-right: 0.5rem;
}

.input-common {
  margin-right: 5px;
}

/deep/ .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 36px;
  line-height: 35px;
  border-bottom: 0px solid transparent;
  color: #909399;
  background-color: #67c23a !important;
  border-radius: 4px;
  width: 109%;
}

/deep/ .el-submenu__title {
  font-family: Roboto, serif;
  font-size: 14px;
  color: #303133;
  padding: 0 20px;
  -webkit-transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  box-sizing: border-box;
}

/deep/ .el-submenu__title i {
  color: #fff;
}

/deep/ .el-menu--popup-right-start {
  margin-left: 5px;
  margin-right: 5px;
}

/deep/ .el-menu--horizontal {
  border-right: none;
}

/deep/ .el-menu--horizontal {
  width: auto;
  border-right: none;
  border-radius: 4px;
}

.loading_title {
  font-family: Roboto, serif;
  font-size: 14px;
  color: red;
  float: right;
  margin-right: 1.5rem;
}

/deep/ .el-loading-spinner .circular {
  height: 33px;
  width: 42px;
  -webkit-animation: loading-rotate 2s linear infinite;
  animation: loading-rotate 2s linear infinite;
}
</style>