<template>
  <div>
    <el-dialog
        v-if="inventoryList.length !== 0"
        :title="'Tồn hóa đơn: ' + fullName + ' - ' + kidCode "
        :visible.sync="isOpenInventoryOrder"
        width="1510px"
        :before-close="handleClose"
        :close-on-click-modal="false"
        top="5vh"
    >
      <el-table
          ref="multipleTable"
          highlight-current-row
          :data="inventoryList"
          :cell-style="tableRowStyle"
          :header-cell-style="tableHeaderColor"
          border
      >
        <el-table-column
            type="index"
            fixed
            label="STT"
            width="50"
            align="center"
        ></el-table-column>
        <el-table-column
            width="90"
            fixed
            align="center"
            label="Tháng"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.month }}</span>

          </template>
        </el-table-column>
        <el-table-column
            label="Mã hóa đơn"
            width="180"
            align="center"
            prop="name"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.orderKidCode }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="Phải thu tháng này"
            width="140"
            prop="name"
            align="right"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.sumMoney | formatCurrency }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Đã thu" align="right" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.collectedMoney | formatCurrency }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Tiền mặt" align="right" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.cashMoney | formatCurrency }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Chuyển khoản" align="right" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.transferMoney | formatCurrency }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Thiếu / Thừa tháng trước" align="right" width="130">
          <template slot-scope="scope">
            <span>{{ scope.row.beforeMoney | formatCurrency }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Thiếu / Thừa tháng này" align="right" width="130">
          <template slot-scope="scope">

            <span v-if="scope.row.afterMoney<0">({{ Math.abs(scope.row.afterMoney) | formatCurrency }})</span>
            <span v-else>{{ scope.row.afterMoney | formatCurrency }}</span>

          </template>
        </el-table-column>
        <el-table-column label="Chuyển thanh toán" align="center" width="150">
          <template slot-scope="scope">

            <span v-if="scope.row.transactionEntry==='MONEY_WALLET'">Không chuyển</span>
            <span v-if="scope.row.transactionEntry==='MONEY_MONTH'">Sang tháng sau</span>

          </template>
        </el-table-column>
        <el-table-column label="Đợt thu" align="center" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.collectionTurn }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Tác vụ" align="center" min-width="120" width="140">
          <template slot-scope="scope">
            <el-button
                v-if="checkPermission(['fees_2_excel_cash_payment'])"
                type="success"
                size="mini"
                @click="orderKidsCashierMethod(scope.row)"
                >Thanh toán
            </el-button>
<!--            <el-button-->
<!--                size="mini"-->
<!--                type="warning"-->
<!--                @click="changeStatusTransactionEntry(scope.row)"-->
<!--                :disabled="scope.row.transactionEntry!=='MONEY_MONTH'">Sửa-->
<!--            </el-button>-->

          </template>
        </el-table-column>

      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" size="medium" @click="handleClose()">
          <i class="el-icon-circle-close"/>
          <span>{{ $t("button.close") }}</span>
        </el-button>
      </span>
    </el-dialog>

    <OrderKidExcelCashierGroup
        :isOpenCashierDialog="showOrderKidsExcelCashierDialog"
        @dialog-close="closeOrderKidsExcelCashierDialog()"
        ref="OrderKidsExcelCashier"
    />

  </div>
</template>

<script>

import {mapState} from "vuex";
import checkPermission from "@/utils/permission";
import groupExcelService from "@/services/Finance_Kids_V2/GroupExcelService";
import OrderKidExcelCashierGroup
  from "@/components/FinanceVer2/ByGroup/GroupExcelTableDialog/OrderKidsExcelCashierGroup.vue";

export default {
  name: "InventoryOrderForGroupDialog",
  computed: {
    ...mapState("groupExcelModule", ["dataSearch"]),
  },
  components: {
    OrderKidExcelCashierGroup,
  },

  data() {
    return {
      fullName: "",
      kidCode: "",
      inventoryList: [],

      idKid: "",
      collectionTurn: 0,
      dataRequest: {
        month: 0,
        idGroup:"",
        kidCode: "",
        createdYear: ""
      },
      showOrderKidsExcelCashierDialog: false,
    }
  },

  props: {
    isOpenInventoryOrder: {
      type: Boolean,
      default: false
    }
  },


  methods: {
    checkPermission,
    getInventoryOrder(kidCode, fullName, idKid, idGroup) {
      this.dataRequest.month = this.dataSearch.month;
      this.dataRequest.idGroup = idGroup
      this.dataRequest.createdYear = this.dataSearch.createdYear;
      this.dataRequest.kidCode = kidCode;
      this.fullName = fullName;
      this.kidCode = kidCode;
      this.idKid = idKid;
      groupExcelService.listRemainingBill(this.dataRequest).then((resp) => {
        this.inventoryList = resp.data;
      })
    },


    handleClose() {

      this.$emit("closeInventoryOrder", false);
      this.fullName = "";
      this.kidCode = "";
      this.inventoryList = [];
      this.idKid = "";
      this.$store.dispatch("groupExcelModule/init_kids_data", this.dataSearch);

    },

    tableRowStyle({row}) {
      if (row.category === "out") {
        return "color: #409EFF";
      }
    },
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },

    /**
     * Mở dialog lưu lịch sử thanh toán
     * @param row
     */
    orderKidsCashierMethod(row) {
      let idKid = this.idKid;
      let kidCode = this.kidCode;
      let kidName = this.fullName;
      let orderKidCode = row.orderKidCode;
      let month = row.month;
      let year = this.dataSearch.createdYear
      let collectionTurn = row.collectionTurn;
      let idGroup=this.dataSearch.idGroup
      this.showOrderKidsExcelCashierDialog = true;
      this.$refs.OrderKidsExcelCashier.getDataCashierInitialForGroup(
          idKid,
          kidCode,
          kidName,
          month,
          collectionTurn,
          orderKidCode,
          year,
          idGroup
      );

    },
    closeOrderKidsExcelCashierDialog() {
      this.showOrderKidsExcelCashierDialog = false;
      groupExcelService.listRemainingBill(this.dataRequest).then((resp) => {
        this.inventoryList = resp.data;
      })
      // this.searchOrderKidsByProperties();
    },

    changeStatusTransactionEntry(row) {

      this.$confirm(
          "Bạn có chắc chắn muốn chuyển thanh toán hay không ? ",
          {
            distinguishCancelAndClose: true,
            confirmButtonText: "Có",
            closeOnClickModal: false,
            cancelButtonText: "Không",
          }
      ).then(() => {
        let request = {
          month: 0,
          kidCode: "",
          newTransactionEntry: "MONEY_WALLET",
          collectionTurn: 0,
          createdYear:'',
          idGroup: "",
        }
        request.month = row.month
        request.kidCode = this.kidCode
        request.collectionTurn = row.collectionTurn
        request.createdYear = this.dataSearch.createdYear
        request.idGroup = this.dataSearch.idGroup
        groupExcelService.updateTransactionEntryForGroup(request)
            .then(() => {
              this.$message({
                message: "Chuyển thanh toán thành công",
                type: "success",
              });

              this.$store.dispatch("groupExcelModule/init_kids_data", this.dataSearch);

              this.handleAfterChangeStatusSuccess()
            })
            .catch(() => {
              this.$message({
                message: "Chuyển thanh toán thất bại",
                type: "error",
              });
            });
      })
    },
    /**
     * Load lai dữ liệu khi thay đổi trạng thái kết chuyển thành công
     */
    handleAfterChangeStatusSuccess() {
      groupExcelService.listRemainingBill(this.dataRequest).then((resp) => {
        this.inventoryList = resp.data;
      })
    }


  }
}
</script>

<style scoped>

</style>

