var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "700px",
        top: "50px",
        visible: _vm.isOpenKidDetail,
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.isOpenKidDetail = $event
        },
        click: _vm.handleOutsideClick,
      },
    },
    [
      _c("div", [_c("h2", [_vm._v("Thông tin bổ sung")])]),
      _c(
        "h4",
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 12 } }, [_vm._v("Tiêu đề")]),
              _c("el-col", { attrs: { span: 4 } }, [
                _vm._v(_vm._s("Tháng " + _vm.dataSearch.month)),
              ]),
              _c("el-col", { attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm.monthBefore)),
              ]),
              _c("el-col", { attrs: { span: 4 } }, [_vm._v("Excel")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [_vm._v("Học T2-T6")]),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.updateFields.arriveT2t6Total)),
          ]),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.updateFields.arriveT2t6TotalMonthBefore)),
          ]),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm.updateFields.arriveT2t6)),
              ])
            : _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.arriveT2t6,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "arriveT2t6", $$v)
                      },
                      expression: "updateFields.arriveT2t6",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [_vm._v("Học T7")]),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.updateFields.arriveT7Total)),
          ]),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.updateFields.arriveT7TotalMonthBefore)),
          ]),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm.updateFields.arriveT7)),
              ])
            : _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.arriveT7,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "arriveT7", $$v)
                      },
                      expression: "updateFields.arriveT7",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [_vm._v("Học CN")]),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.updateFields.arriveCnTotal)),
          ]),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.updateFields.arriveCnTotalMonthBefore)),
          ]),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm.updateFields.arriveCn)),
              ])
            : _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.arriveCn,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "arriveCn", $$v)
                      },
                      expression: "updateFields.arriveCn",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _vm._v("Nghỉ có phép (T2-T6)"),
          ]),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.updateFields.absentCpT2t6Total)),
          ]),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.updateFields.absentCpT2t6TotalMonthBefore)),
          ]),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm.updateFields.absentCpT2t6) + "\n    "),
              ])
            : _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.absentCpT2t6,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "absentCpT2t6", $$v)
                      },
                      expression: "updateFields.absentCpT2t6",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _vm._v("Nghỉ không phép (T2-T6)"),
          ]),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.updateFields.absentKpT2t6Total)),
          ]),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.updateFields.absentKpT2t6TotalMonthBefore)),
          ]),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm.updateFields.absentKpT2t6) + "\n    "),
              ])
            : _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.absentKpT2t6,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "absentKpT2t6", $$v)
                      },
                      expression: "updateFields.absentKpT2t6",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [_vm._v("Nghỉ có phép (T7)")]),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.updateFields.absentCpT7Total)),
          ]),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.updateFields.absentCpT7TotalMonthBefore)),
          ]),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm.updateFields.absentCpT7) + "\n    "),
              ])
            : _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.absentCpT7,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "absentCpT7", $$v)
                      },
                      expression: "updateFields.absentCpT7",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _vm._v("Nghỉ không phép (T7)"),
          ]),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.updateFields.absentKpT7Total)),
          ]),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.updateFields.absentKpT7TotalMonthBefore)),
          ]),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm.updateFields.absentKpT7) + "\n    "),
              ])
            : _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.absentKpT7,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "absentKpT7", $$v)
                      },
                      expression: "updateFields.absentKpT7",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [_vm._v("Đón muộn")]),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.updateFields.leaveLaterTotal)),
          ]),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.updateFields.leaveLaterTotalMonthBefore)),
          ]),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm.updateFields.leaveLater)),
              ])
            : _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.leaveLater,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "leaveLater", $$v)
                      },
                      expression: "updateFields.leaveLater",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _vm._v("Số ngày theo lịch hàng tháng"),
          ]),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.updateFields.quantityDateTotal)),
          ]),
          _c("el-col", { attrs: { span: 4 } }, [
            _vm._v(_vm._s(_vm.updateFields.quantityDateTotalMonthBefore)),
          ]),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { span: 4 } }, [
                _vm._v(_vm._s(_vm.updateFields.quantityDate) + "\n    "),
              ])
            : _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c("el-input", {
                    attrs: { size: "mini", type: "number" },
                    model: {
                      value: _vm.updateFields.quantityDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "quantityDate", $$v)
                      },
                      expression: "updateFields.quantityDate",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 14 } }, [
            _vm._v("(1) Ghi chú nhà trường:"),
          ]),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { offset: 1 } }, [
                _c("i", { staticStyle: { "white-space": "pre-line" } }, [
                  _vm._v(_vm._s(_vm.updateFields.inputNotice1)),
                ]),
              ])
            : _c(
                "el-col",
                { attrs: { offset: 1, span: 23 } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "mini",
                      placeholder: "Nhập tại đây",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.updateFields.inputNotice1,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "inputNotice1", $$v)
                      },
                      expression: "updateFields.inputNotice1",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 18 } }, [
            _vm._v("(2) Ghi chú trên hóa đơn:"),
          ]),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { offset: 1 } }, [
                _c("i", { staticStyle: { "white-space": "pre-line" } }, [
                  _vm._v(_vm._s(_vm.updateFields.inputNotice2)),
                ]),
              ])
            : _c(
                "el-col",
                { attrs: { offset: 1, span: 23 } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "mini",
                      placeholder: "Nhập tại đây",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.updateFields.inputNotice2,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "inputNotice2", $$v)
                      },
                      expression: "updateFields.inputNotice2",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 14 } }, [_vm._v("(3) Ghi chú khác:")]),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { offset: 1 } }, [
                _c("i", { staticStyle: { "white-space": "pre-line" } }, [
                  _vm._v(_vm._s(_vm.updateFields.inputNotice3)),
                ]),
              ])
            : _c(
                "el-col",
                { attrs: { offset: 1, span: 23 } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "mini",
                      placeholder: "Nhập tại đây",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.updateFields.inputNotice3,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "inputNotice3", $$v)
                      },
                      expression: "updateFields.inputNotice3",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 14 } }, [_vm._v("Mô tả đợt thu :")]),
          !_vm.isUpdate
            ? _c("el-col", { attrs: { offset: 1 } }, [
                _c("i", { staticStyle: { "white-space": "pre-line" } }, [
                  _vm._v(_vm._s(_vm.updateFields.descriptionTurn)),
                ]),
              ])
            : _c(
                "el-col",
                { attrs: { offset: 1, span: 23 } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "mini",
                      placeholder: "Nhập tại đây",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.updateFields.descriptionTurn,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateFields, "descriptionTurn", $$v)
                      },
                      expression: "updateFields.descriptionTurn",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isEditing,
                  expression: "isEditing",
                },
              ],
              attrs: { size: "medium", type: "primary" },
              on: { click: _vm.saveInfoAttendance },
            },
            [_vm._v("Cập nhật")]
          ),
          this.statusOrder !== "Ẩn hóa đơn"
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isEditing,
                      expression: "!isEditing",
                    },
                  ],
                  attrs: { size: "medium", type: "success" },
                  on: { click: _vm.handleUpdate },
                },
                [_vm._v("Sửa")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { size: "medium", type: "danger" },
              on: { click: _vm.handleClose },
            },
            [_c("i", { staticClass: "el-icon-circle-close" }), _vm._v(" Đóng")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }