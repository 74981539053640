<template>
  <el-dialog
      title="Thêm bộ lọc cho học sinh"
      :visible.sync="dialogVisible"
      width="550px"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      @open="handleOpen"
  >
    <el-form
        label-width="120px"
        label-position="left"
        ref="dataInput"
    >
      <el-form-item label="Chọn khối">
        <el-select v-model="idGrade" style="width: 100%" @change="handleChangeGrade" clearable>
          <el-option v-for="item in gradeList"
                     :key="item.index"
                     :value="item.id"
                     :label="item.gradeName"
          ></el-option>

        </el-select>
      </el-form-item>
      <el-form-item label="Chọn lớp">
        <el-select v-model="idClass" style="width: 100%" clearable>
          <el-option v-for="item in classList"
                     :key="item.id"
                     :value="item.id"
                     :label="item.className"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
          type="success"
          size="medium"
          mini
          @click="handleSearch"
      >
        <i class="el-icon-circle-check"/>
        <span>{{ $t("button.OK") }}</span>
      </el-button>
      <el-button type="danger" size="medium" @click="closeDialog()">
        <i class="el-icon-circle-close"/>
        <span>{{ $t("button.close") }}</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import GroupExcelService from "@/services/Finance_Kids_V2/GroupExcelService";
import {mapState} from "vuex";

export default {
  name: 'AdditionFilter',
  props: {
    dialogVisible: {
      type: Boolean,
      default: () => false,
    }
  },
  computed:{
    ...mapState('groupExcelModule',['dataSearch'])
  },
  data() {
    return {
      idGrade: '',
      gradeList: [],
      idClass: '',
      classList: [],
      updateDataSearch:{}
    };
  },
  methods: {
    handleSearch(){
      this.$store.commit("groupExcelModule/loading_data")
      this.updateDataSearch=this.dataSearch
      this.updateDataSearch.idGrade=this.idGrade
      this.updateDataSearch.idClass=this.idClass
      this.closeDialog()
      this.$store.dispatch("groupExcelModule/init_kids_data", this.updateDataSearch);

    },
    handleChangeGrade(){
      if(this.idGrade==''){
        this.idClass=''
        this.classList=[]
      }
      else {
        GroupExcelService
            .getClassInGrade(this.idGrade)
            .then((resp) => {
              this.classList = resp.data.data;
              this.classList.length>0?this.idClass=this.classList[0].id:this.idClass=''
            })
            .catch((err) => {
              console.log(err);
            })
      }

    },
    handleOpen() {
      if(this.dataSearch.idGrade){
        this.idGrade=this.dataSearch.idGrade
      }
      if(this.dataSearch.idClass){
        this.idClass=this.dataSearch.idClass
      }
      else{
        GroupExcelService.getGrade().then((resp) => {
          this.gradeList = resp.data.data;
        })
            .catch((err) => {
              console.log(err);
            });
      }

    },
    //reset when click x
    closeDialog() {
      this.$emit("dialog-close", false);
    },

    handleClear(){
      this.idClass = ""
      this.idGrade = ""
    }
  }
}
;
</script>

<style lang="scss" scoped>
</style>
