<template>
  <div class="content">
    <KidsTableSearchGroup>
      <KidsGroupExcelTable/>
    </KidsTableSearchGroup>
  </div>
</template>
<script>
import KidsTableSearchGroup from "@/components/FinanceVer2/ByGroup/KidsTableSearchGroup.vue";
import KidsGroupExcelTable from "@/components/FinanceVer2/ByGroup/KidsGroupExcelTable.vue";

export default {
  components: {
    KidsTableSearchGroup,
    KidsGroupExcelTable
  }
}
</script>