//mixin chủ yếu dùng các chức năng chung, (VD: validate data các con vk, .... )
import {v4} from "uuid";
import financeKidsV2Service from "@/services/Finance_Kids_V2/FinanceKidsV2Service";
import moment from "moment/moment";
import {handleAndDownloadExcel} from "@/utils/dowloadExcel";
import groupExcelService from "@/services/Finance_Kids_V2/GroupExcelService";

export default {
    data(){
        return{
            loadingBill: false,
        }
    },
    methods:{
        handleExportTemplateFile(exportTemplateParams) {
            const guiId = v4();
            // bắt đầu gọi export
            financeKidsV2Service
                .exportExcelTemplate({
                    params: { ...exportTemplateParams, guiId: guiId },
                })
                .then((res) => {
                    // chạy neeus k conf processing
                    if (res.data.message == "processing") {
                        this.$notify.info({
                            title: "Thông báo",
                            message: "Dữ liệu đang được xử lý !",
                        });
                    }
                    // Call the function again after 5 seconds
                    const checkProcess = setInterval(() => {
                        financeKidsV2Service
                            .exportExcelTemplate({
                                params: { ...this.exportTemplateParams, guiId: guiId },
                            })
                            .then((res) => {
                                // chạy neeus k conf processing
                                if (res.data.message == "processing") {
                                    clearInterval(checkProcess)
                                    this.loadingBill =true;
                                    return;
                                }
                                if(res.data.message==="fail"){
                                    this.loadingBill=false
                                    clearInterval(checkProcess)
                                    this.$notify.error({
                                        title: "Thông báo",
                                        message: "Lỗi không xuất được dữ liệu !",
                                    });
                                    return;
                                }
                                if (res.data.message == "complete") {
                                    this.loadingBill =false;
                                    this.$notify.success({
                                        title: "Thông báo",
                                        message: "Dữ liệu xuất thành công",
                                    });
                                    clearInterval(checkProcess);
                                    return financeKidsV2Service
                                        .downloadExcelFile(guiId)
                                        .then((res) =>{
                                            const excelData = res.data;
                                            const excelFilename = `ListKids ${moment().format('YYYY-MM-DD_HH_mm_ss')}.xlsx`; // Provide a desired filename for the downloaded file
                                            handleAndDownloadExcel(excelData, excelFilename);
                                        });
                                }
                            })
                            .catch((err) => {
                                this.loadingBill=false
                                return Promise.reject(err);
                            });
                    }, 3000);
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
        },

        //Xuất excel theo tháng
        handleExportExcelByMonth(guiId, date, idKidList, collectionTurn, idGroup,status, dateAttendance) {
            const monthGroupParams = {
                guiId: guiId,
                idGroup: idGroup,
                date: date,
                idKidList: idKidList,
                collectionTurn: collectionTurn,
                status: status,
                dateAttendance: dateAttendance
            }
            groupExcelService
                .exportExcelByCollectionTurn(monthGroupParams)
                .then((res) => {
                    // chạy neeus k conf processing
                    if (res.data.message === "processing"){
                       this.loadingBill=true
                    }
                    // Call the function again after 5 seconds
                    const checkProcess = setInterval(() => {
                        groupExcelService
                            .exportExcelByCollectionTurn(monthGroupParams)
                            .then((res) => {
                                // chạy neeus k conf processing
                                if (res.data.message === "processing") {
                                    this.loadingBill =true
                                    return;
                                }
                                if(res.data.message=== "fail"){
                                    this.loadingBill =false
                                    clearInterval(checkProcess)
                                    this.$notify.error({
                                        title: "Thông báo",
                                        message: "Lỗi không xuất được dữ liệu !",
                                    });
                                    return;
                                }
                                if (res.data.message !== "processing" && res.data.message !== "fail") {
                                    const fileNameRegex = /complete_(.*).xlsx/;
                                    const matches = res.data.message.match(fileNameRegex);
                                    const fileName = matches[1];
                                    this.loadingBill = false
                                    this.$notify.success({
                                        title: "Thông báo",
                                        message: "Dữ liệu xuất thành công",
                                    });
                                    let excelFilename = res.data.message.split('complete_')[1];
                                    clearInterval(checkProcess);
                                    return financeKidsV2Service
                                        .downloadExcelFile(guiId)
                                        .then((res) => {
                                            const excelData = res.data;
                                            excelFilename = `${fileName}.xlsx`; // Provide a desired filename for the downloaded file
                                            handleAndDownloadExcel(excelData, excelFilename);
                                        });
                                }
                            })
                            .catch((err) => {
                                this.loadingBill =false
                                return Promise.reject(err);
                            });
                    }, 3000);
                })
                .catch((err) => {
                    this.loadingBill =false
                    return Promise.reject(err);
                });
        },

        // Xuất Excel hàng loạt theo tháng
        exportExcelAllByMonth(month,year,idGroup, guiId, status, dateAttendance){
            const collectParams = {
                month: month,
                year: year,
                idGroup: idGroup,
                guiId: guiId,
                status: status,
                dateAttendance: dateAttendance
            }
            groupExcelService.exportExcelForMonth(collectParams).then((response) =>{
                if (response.data.message === "processing") {
                    this.$notify.info({
                        title: "Thông báo",
                        message: "Dữ liệu đang được xử lý !",
                    });
                }
                const checkProcess = setInterval(() => {
                    groupExcelService
                        .exportExcelForMonth(collectParams)
                        .then((res) => {
                            // chạy neeus k conf processing
                            if (res.data.message === "processing") {
                                this.loadingBill =true;
                                return;
                            }
                            if(res.data.message ==="fail"){
                                this.loadingBill =false
                                clearInterval(checkProcess)
                                this.$notify.error({
                                    title: "Thông báo",
                                    message: "Lỗi không xuất được dữ liệu !",
                                });
                                return;
                            }
                            if (res.data.message !== "processing" && res.data.message !== "fail") {
                                const fileNameRegex = /complete_(.*).xlsx/;
                                const matches = res.data.message.match(fileNameRegex);
                                const fileName = matches[1];
                                this.loadingBill =false
                                this.$notify.success({
                                    title: "Thông báo",
                                    message: "Dữ liệu xuất thành công",
                                });
                                let excelFilename = res.data.message.split('complete_')[1];
                                clearInterval(checkProcess);
                                return financeKidsV2Service
                                    .downloadExcelFile(guiId)
                                    .then((res) => {
                                        const excelData = res.data;
                                        excelFilename = `${fileName}.xlsx`; // Provide a desired filename for the downloaded file
                                        handleAndDownloadExcel(excelData, excelFilename);
                                    });
                            }
                        })
                        .catch((err) => {
                            this.loadingBill =false
                            return Promise.reject(err);
                        });
                }, 3000);
            })
        },

    }
}