var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            width: "700px",
            title: "Upload file mẫu Excel học phí",
            visible: _vm.isOpenUploadTemplateSchool,
            "before-close": _vm.handleCloseTemplateSchool,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isOpenUploadTemplateSchool = $event
            },
            open: _vm.handleOpen,
          },
        },
        [
          _c(
            "div",
            { staticClass: "upload-section" },
            [
              _c(
                "el-form",
                {
                  ref: "formData",
                  attrs: {
                    model: _vm.formData,
                    "label-position": "left",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "file-description" },
                    [
                      _c(
                        "el-form-item",
                        { staticStyle: { "margin-top": "20px" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.selectGroupChange },
                              model: {
                                value: _vm.selectGroup,
                                callback: function ($$v) {
                                  _vm.selectGroup = $$v
                                },
                                expression: "selectGroup",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { attrs: { label: "one group" } },
                                [_vm._v("Nhóm hiện tại")]
                              ),
                              _c(
                                "el-radio",
                                { attrs: { label: "many group" } },
                                [_vm._v("Chọn nhiều nhóm")]
                              ),
                              _c(
                                "el-radio",
                                { attrs: { label: "all group" } },
                                [_vm._v("Chọn tất cả nhóm trong trường")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      this.showSelectGroup === true
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "input-common input-grade",
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    multiple: "",
                                    "collapse-tags": "",
                                    clearable: "",
                                    placeholder: "Chọn nhóm",
                                  },
                                  on: {
                                    "visible-change": _vm.handleChangeKidGroup,
                                    "remove-tag": _vm.handleChangeKidGroup,
                                    clear: _vm.handleChangeKidGroup,
                                  },
                                  model: {
                                    value: _vm.idGroupList,
                                    callback: function ($$v) {
                                      _vm.idGroupList = $$v
                                    },
                                    expression: "idGroupList",
                                  },
                                },
                                _vm._l(
                                  _vm.kidGroupList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        value: item.id,
                                        label: item.nameGroup,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                              this.idGroupList.length === 0
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "margin-left": "10px",
                                        color: "red",
                                      },
                                    },
                                    [_vm._v("* Vui lòng chọn nhóm")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Nhập mô tả",
                            prop: "sendDescription",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", rows: 2 },
                            model: {
                              value: _vm.formData.sendDescription,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "sendDescription", $$v)
                              },
                              expression: "formData.sendDescription",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-form-item", [
                    _c(
                      "div",
                      { staticClass: "file-upload-field" },
                      [
                        _c(
                          "el-upload",
                          {
                            ref: "upload",
                            attrs: {
                              action: "#",
                              "auto-upload": false,
                              "on-change": _vm.toggleUpload,
                              "on-preview": _vm.handlePreview,
                              "on-remove": _vm.handleRemove,
                              "before-remove": _vm.beforeRemove,
                              accept: ".xls,.xlsx",
                              "file-list": _vm.formData.fileList,
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "font-size": "15px" },
                                attrs: { size: "small", type: "primary" },
                              },
                              [
                                _vm._v("Upload file "),
                                _c("i", {
                                  staticClass: "el-icon-upload el-icon-right",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "buttons" },
                [
                  this.checkButton === true
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-right": "10px" },
                          attrs: {
                            loading: _vm.loadingUploadTemplate,
                            size: "small",
                            type: "success",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleUpload()
                            },
                          },
                        },
                        [_vm._v("\n          Tải lên\n        ")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "danger" },
                      on: { click: _vm.handleCloseTemplateSchool },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-close" }),
                      _vm._v(" Đóng\n        "),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }