import financeKidsV2Service from "@/services/Finance_Kids_V2/FinanceKidsV2Service";
import {handleAndDownloadExcel} from "@/utils/dowloadExcel";
import groupExcelService from "@/services/Finance_Kids_V2/GroupExcelService";

export default {
    data() {
        return {
            loadingUpdate: false,
            updateAttendance: true,
            updateAttendanceForGroup: true,
            groupParams:{
                idGroup:"",
                month:"",
                year:""
            }
        }
    },
    methods: {

        // cập nhật điểm danh theo nhóm
        updateAttendanceDataForGroup(idGroup, month, year, dataSearch) {
            this.groupParams.idGroup  = idGroup
            this.groupParams.month  = month
            this.groupParams.year  = year
            this.loadingUpdate = true
            this.updateAttendanceForGroup = false;
            this.$store.dispatch("groupExcelModule/init_kids_data", dataSearch);
            groupExcelService.updateTotalKidsArriveForGroup(this.groupParams).then((response) => {
                this.loadingUpdate = false
                this.updateAttendanceForGroup = true
                if (response.data.message === "Cập nhật thành công") {
                    this.$notify.success({
                        title: "Thông báo",
                        message: "Cập nhật thành công",
                    });
                }
            }).catch((error) => {
                    this.loadingUpdate = false
                    console.log(error)
                }
            )
        },

        //Hướng dẫn sử dụng tải file mẫu
        instructUploadFileSample() {
            financeKidsV2Service.instructUploadFileSample().then((res) => {
                const excelData = res.data;
                let excelFilename = `Hướng dẫn sử dụng.xlsx`;
                handleAndDownloadExcel(excelData, excelFilename);
            });
        },

    }
}